import { Image, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";

type ArticleHalfContentProps = {
  title: string;
  subtitle: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const articleHalfContent = {
  fashion: {
    title: "Our Mission",
    subtitle:
      "Our mission is to inspire bold fashion innovation with sustainable solutions.",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
  food: {
    title: "Culinary Excellence",
    subtitle:
      "We're revolutionizing the food industry through sustainable practices and innovative cooking technologies.",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_square",
    }),
    alt: "Kitchen Innovation Image",
    align: "bottomLeft",
  },
  beauty: {
    title: "Clean Beauty",
    subtitle:
      "Pioneering sustainable beauty solutions that combine science and nature for transformative results.",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_square",
    }),
    alt: "Beauty Product Image",
    align: "bottomLeft",
  },
  editorial: {
    title: "The Rise of Long-Form Digital Journalism",
    subtitle:
      "How interactive storytelling is transforming investigative reporting in the digital age.",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
    alt: "Journalist working on investigative story in digital newsroom",
    align: "bottomLeft",
  },
  finance: {
    title: "Financial Innovation",
    subtitle:
      "Empowering individuals and businesses with next-generation financial technology solutions.",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_square",
    }),
    alt: "Finance Technology Image",
    align: "bottomLeft",
  },
  sport: {
    title: "Peak Performance",
    subtitle:
      "Advancing athletic achievement through cutting-edge sports science and technology.",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_square",
    }),
    alt: "Sports Technology Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, ArticleHalfContentProps>;

export default function ArticleHalf({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<ArticleHalfContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Horizontal
      fullW
      fullH
      spaceBetween
      bg={colorScheme.background}
      gap={0}
      px={"md"}
      py={"md"}
      noWrap
      align="flex-end"
      {...props}
    >
      <Vertical gap={"xs"} maw={"50%"} fullH spaceBetween>
        <Title
          variant="card-headline-md"
          c={colorScheme.textdark}
          ta={"left"}
          w={"100%"}
        >
          {content.title}
        </Title>

        <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
          {content.subtitle}
        </Text>
      </Vertical>

      <Image
        loading="eager"
        src={uploadedImagePath ?? content.imgs[imgIndex]}
        alt={content.alt}
        radius={"xxs"}
        fit="cover"
        h={"70%"}
        w={"70%"}
        {...props}
      />
    </Horizontal>
  );
}
