import { Box, Image, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";

type BlogArticleWithImageTopContentProps = {
  title: string;
  description: string;
  button: string;
  imgs: string[];
};

export const blogArticleWithImageTopContent = {
  food: {
    title: "Samba Sweetness",
    description: "Succulent and bold, a dessert that captures bite.",
    button: "Discover",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
  },
  sport: {
    title: "Breaking Barriers",
    description:
      "We exist to bring inspiration and innovation to every athlete",
    button: "Explore",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
  },
  beauty: {
    title: "Beauty Revolution",
    description:
      "Science-backed formulations that transform your skincare routine.",
    button: "Discover",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
  },
  fashion: {
    title: "Sustainable Chic",
    description: "Eco-friendly fashion that doesn't compromise on style.",
    button: "Explore",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
  },
  editorial: {
    title: "Vanishing Traditions",
    description:
      "A journey through the last remaining artisanal workshops in Venice.",
    button: "Read Article",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
  },
} as const satisfies Record<string, BlogArticleWithImageTopContentProps>;

export default function BlogArticleWithImageTop({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BlogArticleWithImageTopContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      gap={"xs"}
      justify="space-between"
      {...props}
    >
      <Box h={"40%"} w={"100%"}>
        <Image
          src={uploadedImagePath ?? content.imgs[imgIndex]}
          fit="cover"
          w={"100%"}
          h={"100%"}
        />
      </Box>

      <Vertical mx="md" gap={"xs"}>
        <Title variant="card-headline-xs" ta={"left"} c={colorScheme.textdark}>
          {content.title}
        </Title>

        <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
          {content.description}
        </Text>
      </Vertical>

      <Text
        bg={colorScheme.ctadark}
        c={colorScheme.textlight}
        py={"xs"}
        px={"xs"}
        mx={"md"}
        mb={"md"}
        variant="card-paragraph-md"
        style={{ borderRadius: "var(--mantine-radius-xs)" }}
      >
        {content.button}
      </Text>
    </Vertical>
  );
}
