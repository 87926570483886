import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { Box, Text, isLightColor } from "@mantine/core";
import React from "react";
import { RandomSection } from "./components/RandomSection";
import { ShareSection } from "./components/ShareSection";
import IconsGrid, { iconsGridContent } from "./components/bentoCards/IconsGrid";
import MenuPdfs, { menuPdfsContent } from "./components/bentoCards/MenuPdfs";
import Savings, { savingsContent } from "./components/bentoCards/Savings";
import TitleWithBgColor, {
  titleWithBgColorContent,
} from "./components/bentoCards/TitleWithBgColor";
import Unicorns, { unicornsContent } from "./components/bentoCards/Unicorns";
import { Horizontal } from "./components/mantine-layout-components/Horizontal";
import { Vertical } from "./components/mantine-layout-components/Vertical";
import { InspoBadge } from "./components/ui/InspoBadge";
import { useColorPalette } from "./contexts/ColorSchemeContext";
import { useFont } from "./contexts/FontsContext";

export function Mobile({}: {}) {
  const { currentColorScheme, handleColorSchemeChange } = useColorPalette();

  return (
    <Vertical h={"calc(100dvh - 60px)"} gap={"md"} bg={"rgba(41, 37, 36, 1)"}>
      <Horizontal fullH mah={"50%"} fullW align="end">
        <Carousel
          w={"100%"}
          h={"80%"}
          align={"center"}
          slideSize="80%"
          slideGap="md"
          orientation="horizontal"
          initialSlide={1}
          loop={true}
          draggable={true}
          dragFree={false}
          withControls={true}
          withIndicators={false}
          skipSnaps={false}
          styles={{
            viewport: {
              height: "100%",
            },
            container: {
              height: "100%",
            },
          }}
          style={{ overflow: "visible" }}
        >
          {[
            // @ts-ignore
            <Unicorns
              colorScheme={currentColorScheme.dark}
              content={unicornsContent.finance}
            />,
            // @ts-ignore
            <Savings
              key={"c1d2e3f4g5"}
              colorScheme={currentColorScheme.bright}
              content={savingsContent.finance}
            />,
            // @ts-ignore
            <IconsGrid
              colorScheme={currentColorScheme.bright}
              content={iconsGridContent.iconFood}
            />,
            // @ts-ignore
            <TitleWithBgColor
              colorScheme={currentColorScheme.dark}
              content={titleWithBgColorContent.beauty}
            />,
            // @ts-ignore
            <MenuPdfs
              key={"4511"}
              colorScheme={currentColorScheme.dark}
              content={menuPdfsContent.beauty}
            />,
          ].map((card, i) => (
            <Carousel.Slide key={i}>
              {React.cloneElement(card, {
                h: "100%",
                style: {
                  borderRadius: "var(--mantine-radius-sm)",
                  overflow: "hidden",
                },
              })}
            </Carousel.Slide>
          ))}
        </Carousel>
      </Horizontal>
      <Vertical
        fullW
        centerV
        justify="end"
        pb={"lg"}
        fullH
        mah={"50%"}
        spaceBetween
      >
        <Vertical flex={"1"} fullW centerV>
          <Horizontal mx={"auto"} fullW gap={"xs"} px={"sm"} justify="start">
            <FontSection type="heading" />
            <FontSection type="body" />
          </Horizontal>
          <ColorSchemeSection currentColorScheme={currentColorScheme} />
        </Vertical>
        <Horizontal fullW gap={"xs"} px={"lg"} maw={"24rem"} mx={"auto"}>
          <RandomSection version="mobile" />
          <ShareSection version="mobile" />
        </Horizontal>
      </Vertical>
    </Vertical>
  );
}

function FontSection({ type }: { type: "heading" | "body" }) {
  const { font } = useFont(type);

  return (
    <Vertical
      py={"sm"}
      bg={"gray.7"}
      centerV
      gap={"xs"}
      c={"white"}
      flex={1}
      style={{
        alignItems: "center",
        borderRadius: "var(--mantine-radius-xxs)",
      }}
    >
      <Text variant="label-sm" ta={"center"} w={"100%"}>
        {type}
      </Text>
      <Text
        variant="paragraph-lg"
        ff={font["font-family"]}
        ta={"center"}
        w={"100%"}
        lineClamp={1}
      >
        {font["readable-font-family"]}
      </Text>
      {font["source"] === "free" && <InspoBadge theme="lime">FREE</InspoBadge>}
      {font["source"] === "adobe" && <InspoBadge theme="sky">ADOBE</InspoBadge>}
      {font["source"] === "custom" && (
        <InspoBadge theme="dark">CUSTOM</InspoBadge>
      )}
      <Text variant="paragraph-sm" ta={"center"} w={"100%"}>
        {font["foundry"]}
      </Text>
    </Vertical>
  );
}

function ColorSchemeSection({
  currentColorScheme,
}: {
  currentColorScheme: any;
}) {
  return (
    <Horizontal gap={"xxs"} mx={"auto"}>
      {[
        currentColorScheme.dark.background,
        currentColorScheme.light.background,
        currentColorScheme.bright.background,
      ].map((c, i) => {
        return (
          <Box
            key={c + i}
            bg={c}
            p={"xxs"}
            style={{ borderRadius: "var(--mantine-radius-xxs)" }}
          >
            <Text variant="label-md" c={isLightColor(c) ? "black" : "white"}>
              {c}
            </Text>
          </Box>
        );
      })}
    </Horizontal>
  );
}
