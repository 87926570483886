import {
  BackgroundImage,
  Overlay,
  Text,
  Title
} from "@mantine/core";
import type { BentoCardChildrenProps, InspoTypeCardProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";
import { OverlayedBackgroundImage } from "../ui/InspoOverlayedBackgroundImage";

type ImagesLaunchContentProps = {
  title: string;
  button: string;
  imgs: string[];
};

export const imagesLaunchContent = {
  fashion: {
    title: "Unlock\nyour creativity",
    button: "Explore",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
  },
  food: {
    title: "Discover\nFlavor",
    button: "Taste Now",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
  },
  beauty: {
    title: "Reveal\nYour Glow",
    button: "Transform",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
  },
  editorial: {
    title: "Explore\nStories",
    button: "Read More",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
  },
  finance: {
    title: "Build\nWealth",
    button: "Start Now",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
  },
  sport: {
    title: "Achieve\nGreatness",
    button: "Train Now",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
  },
} as const satisfies Record<string, ImagesLaunchContentProps>;

export default function ImageLaunch({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<ImagesLaunchContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <OverlayedBackgroundImage
      overlayGradient="linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)"
      overlayOpacity={0.6}
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
        position: "relative",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      {...props}
    >
      <Vertical
        fullW
        fullH
        spaceBetween
        centerH
        py={"sm"}
        px={"sm"}
        pos={"relative"}
      >
        <Title
          variant="card-headline-md"
          c={colorScheme.textcontrast}
          ta={"start"}
        >
          {content.title}
        </Title>

        <Text
          bg={colorScheme.ctadark}
          c={colorScheme.textlight}
          py={"xs"}
          px={"sm"}
          ta={"center"}
          variant="card-paragraph-md"
          style={{ borderRadius: "var(--mantine-radius-xs)" }}
        >
          {content.button}
        </Text>
      </Vertical>
    </OverlayedBackgroundImage>
  );
}
