import {
  ActionIcon,
  Anchor,
  Box,
  Divider,
  Modal,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { IconCheck, IconRosetteFilled, IconX } from "@tabler/icons-react";
import { ModalKind, track } from "../utils";
import typo from "./../../../styles/Typography.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoButton } from "./ui/InspoButton";

import { colorSchemes } from "src/utils/colorScheme/palette";
import { fontPairs } from "src/utils/typography/configs/fontPairs";
import { InspoSocialProof } from "./ui/InspoSocialProof";
import { useUser } from "../contexts/UserContext";
import { modals, type ContextModalProps } from "@mantine/modals";
import { openModalLoginIfNeeded } from "./ModalLogin";

const lemonSqueezyUrls = (isProd: boolean) => {
  return isProd
    ? "https://inspotype.lemonsqueezy.com/checkout/buy/018b0fe0-66b7-473e-9a63-1dd809ac69e5"
    : "https://inspotype.lemonsqueezy.com/checkout/buy/8209b2c9-24c5-464f-aadb-ffc73f141b25";
};

export function openModalLifetimeIfNeeded({
  user,
  isPro,
  source,
  additionalTrackProperties,
}: {
  user: any;
  isPro: boolean;
  source: string;
  additionalTrackProperties?: Record<string, any>;
}) {
  if (!user) {
    openModalLoginIfNeeded({
      user,
      isMobile: false,
      source: "lifetime",
    });
    return false;
  }

  if (isPro) return false;

  track({
    event: "open_lifetime_modal",
    properties: { source: source, ...additionalTrackProperties },
  });

  modals.openContextModal({
    modal: ModalKind.LIFETIME,
    innerProps: {},
    centered: true,
    trapFocus: false,
    autoFocus: false,
    size: "500px",
    withCloseButton: false,
    padding: 0,
  });

  return true;
}

export function ModalLifetime({}: ContextModalProps<{}>) {
  const { isProd } = useUser();
  const productUrl = lemonSqueezyUrls(isProd);

  const data = {
    featuresSection: {
      title: "Inspiration that\nlasts a lifetime",
      subtitle: "Go PRO to unlock all features\nfor unlimited paid projects.",
      line1:
        "Inspotype is just getting started,\nbut it can already save you hours of boring work.",
      line3:
        "We're running a limited offer for early adopters to fund the project. You can help us make sure Inspotype is here to stay.",
      availableFeatures: {
        title: "Full app access",
        features: [
          "Upload custom images to you moodboards",
          "Save moodboards to your library of projects",
          "Pick & Lock individual fonts and colors",
          "Local font access",
          "Customize any color palette",
          `${fontPairs.length} curated font pairings`,
          `${colorSchemes.length} color palettes`,
          `${6} real-life industry mockups`,
        ],
      },
    },
    pricingSection: {
      badge: "early bird deal",
      price: "$97",
      oldPrice: "$197",
      line1: "One-time payment.",
      line2: "Grand-fathered access forever.",
      cta: "Pay once, use forever",
    },
  };

  return (
    <>
      <ActionIcon
        variant="transparent"
        color="gray"
        pos={"absolute"}
        top={0}
        right={0}
        mt={"sm"}
        mr={"sm"}
        onClick={() => modals.closeAll()}
      >
        <IconX size={20} />
      </ActionIcon>
      {/* features section */}
      <Vertical
        bg={"rgba(245, 245, 245, 1)"}
        py={"xl"}
        px={"xl"}
        fullH
        fullW
        center
      >
        {/* header */}
        <Vertical gap={"md"} center maw={"500px"} mx={"auto"}>
          <Title variant="display-sm" ta={"center"}>
            {data.featuresSection.title}
          </Title>
          <Text variant="paragraph-xl" ta={"center"}>
            {data.featuresSection.subtitle}
          </Text>
        </Vertical>

        {/* feature list */}
        <Vertical gap={0}>
          {data.featuresSection.availableFeatures.features.map((feature, i) => {
            return (
              <Box key={i} w={"100%"}>
                <Horizontal noWrap gap={"sm"} my={"xs"}>
                  <Box h={"16px"} mt={"-14px"}>
                    <IconRosetteFilled size={16} />
                    <IconCheck
                      size={8}
                      style={{
                        marginLeft: "-12.2px",
                        marginBottom: "4px",
                      }}
                      color="white"
                    />
                  </Box>
                  <Text variant="paragraph-md">{feature}</Text>
                </Horizontal>
              </Box>
            );
          })}
        </Vertical>

        <Text variant="paragraph-sm">
          Check out the{" "}
          <Anchor
            c={"black"}
            td={"underline"}
            target="_blank"
            href="https://coral-gum-324.notion.site/f9f2d23d23824e30852e7854bbfba61a?v=ade8374fc3f941dbbea14a99048b566a"
            classNames={{ root: typo.paragraphSm }}
          >
            Public Roadmap
          </Anchor>{" "}
          to see what's coming.
        </Text>

        {/* cta button */}
        <InspoButton
          theme="accent"
          h={66}
          w={"100%"}
          onClick={() => {
            track({
              event: "checkout_start",
              properties: {},
            });
            window.LemonSqueezy.Url.Open(productUrl);
            // const url = window.LemonSqueezy.Affiliate.Build(productUrl);
            // window.LemonSqueezy.Url.Open(url);
          }}
        >
          <Horizontal fullW spaceBetween>
            <Text
              style={{
                fontFamily: "Clash Display",
                fontSize: "18px",
                letterSpacing: "0.4px",
                whiteSpace: "pre-wrap",
              }}
            >
              {data.pricingSection.cta}
            </Text>
            <Text
              style={{
                fontFamily: "Clash Display",
                fontSize: "18px",
                letterSpacing: "0.4px",
                whiteSpace: "pre-wrap",
              }}
            >
              {data.pricingSection.price}
            </Text>
          </Horizontal>
        </InspoButton>
      </Vertical>
      {/* social proof */}
      <Space h={"md"} />
      <InspoSocialProof />
      <Space h={"md"} />
    </>
  );
}