import {
  Anchor,
  Chip,
  Divider,
  Grid,
  Highlight,
  Input,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useSessionStorage } from "@mantine/hooks";
import { IconLicense, IconSearch, IconTypography } from "@tabler/icons-react";
import { useEffect, useMemo, useRef } from "react";
import { fontPairs } from "src/utils/typography/configs/fontPairs";
import { useFont } from "../contexts/FontsContext";
import { useUser } from "../contexts/UserContext";
import { track } from "../utils";
import typo from "./../../../styles/Typography.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoChip } from "./ui/InspoChip";
import { InspoPickerFilterSection } from "./ui/InspoPickerFilterSection";
import { InspoPickerListButton } from "./ui/InspoPickerListButton";

export function FontPickerTabCurated({
  type,
  setShow,
}: {
  type: "heading" | "body";
  setShow: (value: boolean) => void;
}) {
  const { font, isLockedAt, setIsLockedAt, setIsLockedAtFont, setIsLocal } =
    useFont(type);

  const [curatedFontsSelectedLicenses, setCuratedFontsSelectedLicenses] =
    useSessionStorage<string[]>({
      key: {
        heading: `curated_fonts_licenses_heading`,
        body: `curated_fonts_licenses_body`,
      }[type],
      defaultValue: ["free", "adobe", "custom"],
    });

  const [curatedFontsCategories, setCuratedFontsCategories] = useSessionStorage<
    string[]
  >({
    key: {
      heading: `curated_fonts_categories_heading`,
      body: `curated_fonts_categories_body`,
    }[type],
    defaultValue: [
      {
        heading: "heading",
        body: "body",
      }[type],
    ],
  });

  const [curatedFontSearchValue, setCuratedFontSearchValue] = useSessionStorage(
    {
      key: {
        heading: `curated_font_search_value_heading`,
        body: `curated_font_search_value_body`,
      }[type],
      defaultValue: "",
    },
  );

  const filteredCuratedFonts = useMemo(() => {
    return fontPairs
      .reduce(
        (acc, curr) => {
          function addToAcc(fontType: "title" | "body") {
            const pairFont = curr[fontType]["readable-font-family"];
            const pairFontFamily = curr[fontType]["font-family"];

            if (
              !!pairFont &&
              curatedFontsCategories.includes(type) &&
              !acc.find((f) => f.font === pairFont)
            ) {
              if (
                (curatedFontsSelectedLicenses.includes("free") &&
                  curr[fontType].source === "free") ||
                (curatedFontsSelectedLicenses.includes("adobe") &&
                  curr[fontType].source === "adobe") ||
                (curatedFontsSelectedLicenses.includes("custom") &&
                  curr[fontType].source === "custom")
              ) {
                acc.push({
                  font: pairFont,
                  fontFamily: pairFontFamily,
                  source: curr[fontType].source,
                  foundry: curr[fontType].foundry,
                });
              }
            }
          }

          addToAcc(type === "heading" ? "title" : "body");

          return acc;
        },
        [] as {
          font: string;
          fontFamily: string | undefined;
          source: string | undefined;
          foundry: string | undefined;
        }[],
      )
      .filter(
        (f: any) =>
          f.font.toLowerCase().includes(curatedFontSearchValue.trim()) ||
          f.foundry?.toLowerCase().includes(curatedFontSearchValue.trim()),
      )
      .toSorted((a, b) => a.font.localeCompare(b.font));
  }, [
    curatedFontsCategories,
    curatedFontsSelectedLicenses,
    curatedFontSearchValue,
  ]);

  const viewportRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const selectedIndex = filteredCuratedFonts.findIndex(
      (f: any) => f.font === font["readable-font-family"],
    );

    viewportRef.current
      ?.querySelectorAll("[data-list-item]")
      ?.[selectedIndex]?.scrollIntoView({ block: "center" });
  }, []);

  return (
    <Vertical gap={"sm"} fullW noWrap fullH style={{ zIndex: 3 }}>
      <Filters
        curatedFontsCategories={curatedFontsCategories}
        setCuratedFontsCategories={setCuratedFontsCategories}
        curatedFontsSelectedLicenses={curatedFontsSelectedLicenses}
        setCuratedFontsSelectedLicenses={setCuratedFontsSelectedLicenses}
      />

      <Input
        placeholder="Search a font"
        w={"100%"}
        leftSection={<IconSearch size={16} color="white" />}
        value={curatedFontSearchValue}
        autoFocus
        onChange={(e) => setCuratedFontSearchValue(e.currentTarget.value)}
        classNames={{
          input: typo.paragraphMd,
        }}
        styles={{
          input: {
            color: "white",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderColor: "transparent",
          },
        }}
      />

      <Divider w={"100%"} color={"#464444"} />

      <ScrollArea
        viewportRef={viewportRef}
        mt={"-16px"}
        style={{
          height: "285px",
          width: "100%",
        }}
      >
        {filteredCuratedFonts.map((item, index) => {
          return (
            <InspoPickerListButton
              key={item.font}
              selected={item.font === font["readable-font-family"]}
              onClick={() => {
                // if (
                //   openModalLifetimeIfNeeded({
                //     user,
                //     isPro,
                //     source: "font_picker",
                //     additionalTrackProperties: {
                //       source2: "font_picker_curated",
                //     },
                //   })
                // ) {
                //   return;
                // }

                if (isLockedAt === undefined) {
                  setIsLockedAt(index);
                }

                setIsLockedAtFont(item.font);
                setIsLocal(false);
                setShow(false);

                track({
                  event: "select_font",
                  properties: { category: "curated", source: item.source },
                });
              }}
            >
              <Grid style={{ overflow: "hidden" }}>
                <Grid.Col span={6} m={"auto"}>
                  <Text variant="paragraph-md">
                    <Highlight
                      highlight={curatedFontSearchValue}
                      c={
                        item.font === font["readable-font-family"]
                          ? "black"
                          : undefined
                      }
                      ta={"left"}
                      classNames={{ root: typo.paragraphMd }}
                      style={{
                        fontFamily: item.fontFamily,
                      }}
                      highlightStyles={{
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      {item.font}
                    </Highlight>
                  </Text>
                </Grid.Col>
                <Grid.Col span={3} m={"auto"}>
                  <Text
                    variant="label-sm"
                    style={{ textWrap: "nowrap" }}
                    ta={"left"}
                  >
                    <Highlight
                      highlight={curatedFontSearchValue}
                      classNames={{ root: typo.labelSm }}
                      highlightStyles={{
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      {item.foundry ?? ""}
                    </Highlight>
                  </Text>
                </Grid.Col>
                <Grid.Col span={3} m={"auto"}>
                  <Vertical w="100%" h="100%" alignEnd>
                    {(() => {
                      const badge = {
                        free: { theme: "lime", label: "FREE" },
                        adobe: { theme: "sky", label: "ADOBE" },
                        custom: { theme: "dark", label: "CUSTOM" },
                      }[item.source as keyof typeof badge];

                      return (
                        badge && (
                          <InspoBadge
                            theme={badge.theme as "lime" | "sky" | "dark"}
                          >
                            {badge.label}
                          </InspoBadge>
                        )
                      );
                    })()}
                  </Vertical>
                </Grid.Col>
              </Grid>
            </InspoPickerListButton>
          );
        })}
      </ScrollArea>
    </Vertical>
  );
}

function Filters({
  curatedFontsSelectedLicenses,
  setCuratedFontsSelectedLicenses,
  curatedFontsCategories,
  setCuratedFontsCategories,
}: {
  curatedFontsSelectedLicenses: string[];
  setCuratedFontsSelectedLicenses: (value: string[]) => void;
  curatedFontsCategories: string[];
  setCuratedFontsCategories: (value: string[]) => void;
}) {
  const licenseOptions = ["free", "adobe", "custom"];
  const categoriesOptions = ["heading", "body"];

  return (
    <>
      {/* licenses filter */}
      <InspoPickerFilterSection title="license" icon={<IconLicense />}>
        <Chip.Group
          multiple
          value={curatedFontsSelectedLicenses}
          onChange={setCuratedFontsSelectedLicenses}
        >
          <Horizontal gap={"xxs"}>
            {licenseOptions
              .map((key) => ({
                value: key,
                label: key.toUpperCase(),
              }))
              .map((item) => (
                <InspoChip
                  key={item.value}
                  value={item.value}
                  checked={curatedFontsSelectedLicenses.includes(item.value)}
                >
                  {item.label}
                </InspoChip>
              ))}
          </Horizontal>
        </Chip.Group>
      </InspoPickerFilterSection>

      {/* categories filter */}
      {/* <InspoPickerFilterSection title="categories" icon={<IconTypography />}>
        <Chip.Group
          multiple
          value={curatedFontsCategories}
          onChange={setCuratedFontsCategories}
        >
          <Horizontal gap={"xxs"}>
            {categoriesOptions
              .map((key) => ({
                value: key,
                label: key.toUpperCase(),
              }))
              .map((item) => (
                <InspoChip
                  key={item.value}
                  value={item.value}
                  checked={curatedFontsCategories.includes(item.value)}
                >
                  {item.label}
                </InspoChip>
              ))}
          </Horizontal>
        </Chip.Group>
      </InspoPickerFilterSection> */}
    </>
  );
}
