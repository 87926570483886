import { Text } from "@mantine/core";
import { IconArrowBackUp, IconArrowForwardUp } from "@tabler/icons-react";
import { Horizontal } from "./mantine-layout-components/Horizontal";

import { debounce, InspoEvent, track } from "../utils";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoKeyboardShortcutTooltip } from "./ui/InspoKeyboardShortcutTooltip";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { useUser } from "../contexts/UserContext";
import { useHotkeys } from "@mantine/hooks";
import { useCardsState } from "../contexts/CardsStateContext";
import { useFonts } from "../contexts/FontsContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useEffect, useState } from "react";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";

export const HistorySection = ({}: {}) => {
  const { user } = useUser();
  const { cardsState, setCardsState } = useCardsState();
  const { fontPairIndex, setFontPairIndex } = useFonts();
  const { colorSchemeIndex, setColorSchemeIndex } = useColorPalette();

  const [history, setHistory] = useState<
    Array<{
      fontPairingIndex: number;
      colorSchemeIndex: number;
      cardsState: Record<string, BentoCardChildrenProps>;
    }>
  >([]);

  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false);

  useEffect(() => {
    function handleSetHistoryCheckpoint() {
      setShouldUpdateHistory(true);
    }

    document.addEventListener(
      InspoEvent.SET_HISTORY_CHECKPOINT,
      handleSetHistoryCheckpoint,
    );

    return () => {
      document.removeEventListener(
        InspoEvent.SET_HISTORY_CHECKPOINT,
        handleSetHistoryCheckpoint,
      );
    };
  }, []);

  useEffect(() => {
    const debouncedUpdateHistory = debounce(() => {
      const currentHistoryStep = history[currentHistoryIndex];
      const isFirstStep = currentHistoryIndex === 0 && history.length === 0;

      if (!isFirstStep) {
        if (currentHistoryStep === undefined) {
          return;
        }

        if (
          history[currentHistoryIndex].fontPairingIndex === fontPairIndex &&
          history[currentHistoryIndex].colorSchemeIndex === colorSchemeIndex &&
          (JSON.stringify(history[currentHistoryIndex].cardsState) ===
            JSON.stringify(cardsState) ||
            Object.keys(cardsState).length === 0)
        ) {
          return;
        }
      }

      setHistory((prev) => {
        const newHistory = [
          ...prev.slice(0, currentHistoryIndex + 1),
          {
            fontPairingIndex: fontPairIndex,
            colorSchemeIndex: colorSchemeIndex,
            cardsState: cardsState,
          },
        ];
        return newHistory;
      });

      if (!isFirstStep) {
        setCurrentHistoryIndex((prev) => prev + 1);
      }

      setShouldUpdateHistory(false);
    }, 200);

    if (shouldUpdateHistory) {
      debouncedUpdateHistory();
    }

    return () => {
      debouncedUpdateHistory.cancel();
    };
  }, [
    shouldUpdateHistory,
    currentHistoryIndex,
    history,
    colorSchemeIndex,
    fontPairIndex,
    cardsState,
  ]);

  useHotkeys([
    [
      "alt+z",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.z",
          })
        ) {
          return;
        }
        track({
          event: "change_history",
          properties: { source: "keyboard", action: "undo" },
        });
        undo();
      },
    ],
    [
      "alt+shift+z",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.shiftz",
          })
        ) {
          return;
        }
        track({
          event: "change_history",
          properties: { source: "keyboard", action: "redo" },
        });
        redo();
      },
    ],
  ]);

  function undo() {
    if (currentHistoryIndex <= 0) return;
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "history.undo",
      })
    ) {
      return;
    }
    setFontPairIndex(history[currentHistoryIndex - 1].fontPairingIndex);
    setColorSchemeIndex(history[currentHistoryIndex - 1].colorSchemeIndex);
    setCardsState(history[currentHistoryIndex - 1].cardsState);
    setCurrentHistoryIndex((prev) => prev - 1);
  }

  function redo() {
    if (currentHistoryIndex >= history.length - 1) return;
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "history.redo",
      })
    ) {
      return;
    }
    setFontPairIndex(history[currentHistoryIndex + 1].fontPairingIndex);
    setColorSchemeIndex(history[currentHistoryIndex + 1].colorSchemeIndex);
    setCardsState(history[currentHistoryIndex + 1].cardsState);
    setCurrentHistoryIndex((prev) => prev + 1);
  }

  return (
    <Horizontal gap={"sm"} h={44} center noWrap>
      <Text variant="label-lg">HISTORY</Text>

      <Horizontal
        gap={"xxxs"}
        noWrap
        style={{
          borderRadius: "var(--mantine-radius-xs)",
          overflow: "hidden",
        }}
      >
        <InspoKeyboardShortcutTooltip
          label={"Previous change"}
          shortcut={"alt + z"}
        >
          <InspoActionIcon
            theme="light"
            disabled={currentHistoryIndex <= 0}
            icon={<IconArrowBackUp />}
            radius={0}
            onClick={() => {
              track({
                event: "change_history",
                properties: { source: "button", action: "undo" },
              });
              undo();
            }}
          />
        </InspoKeyboardShortcutTooltip>

        <InspoKeyboardShortcutTooltip
          label={"Next change"}
          shortcut={"alt + shift + z"}
        >
          <InspoActionIcon
            theme="light"
            disabled={currentHistoryIndex >= history.length - 1}
            icon={<IconArrowForwardUp />}
            radius={0}
            onClick={() => {
              track({
                event: "change_history",
                properties: { source: "button", action: "redo" },
              });
              redo();
            }}
          />
        </InspoKeyboardShortcutTooltip>
      </Horizontal>
    </Horizontal>
  );
};
