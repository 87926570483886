import { Image, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";

type BlogArticleWithImageRightContentProps = {
  title: string;
  subtitle: string;
  imgs: string[];
  alt: string;
};

export const blogArticleWithImageRightContent = {
  finance: {
    title: "Asset Ace Secures Investment and Appoints CEO",
    subtitle:
      "Shares of Asset Ace, Inc. (ticker symbol ASAC) rose 5% today on news that the financial tech company has secured a major investment.",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
  },
} as const satisfies Record<string, BlogArticleWithImageRightContentProps>;

export default function BlogArticleWithImageRight({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BlogArticleWithImageRightContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Horizontal
      fullW
      fullH
      center
      bg={colorScheme.background}
      px={"md"}
      py={"md"}
      gap={"lg"}
      noWrap
      {...props}
    >
      <Vertical gap={"sm"} center maw={"50%"}>
        <Title variant="card-headline-sm" c={colorScheme.textdark} ta={"start"}>
          {content.title}
        </Title>

        <Text variant="card-paragraph-sm" c={colorScheme.textdark} ta={"start"}>
          {content.subtitle}
        </Text>
      </Vertical>
      <Image
        src={uploadedImagePath ?? content.imgs[imgIndex]}
        alt={content.alt}
        radius={"xxs"}
        fit="cover"
        style={{ flexGrow: 1 }}
        h={"100%"}
        w={"50%"}
        mx={"auto"}
      />
    </Horizontal>
  );
}
