import { Divider, Space, Text, Title } from "@mantine/core";
import React from "react";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";

type UnicornsContentProps = {
  title: string;
  subtitle: string;
  items: {
    title: string;
    number: string;
  }[];
};

export const unicornsContent = {
  finance: {
    title: "Most valuable unicorns",
    subtitle: "Top 5 unicorns that received investments through AngelList",
    items: [
      {
        title: "Solana",
        number: "$110B",
      },
      {
        title: "Celo",
        number: "$100B",
      },
      {
        title: "Near",
        number: "$90B",
      },
    ],
  },
  beauty: {
    title: "Skincare bestsellers",
    subtitle: "Most popular beauty products",
    items: [
      {
        title: "Serum",
        number: "$120",
      },
      {
        title: "Cream",
        number: "$85",
      },
      {
        title: "Mask",
        number: "$45",
      },
    ],
  },

  fashion: {
    title: "Sustainable fashion",
    subtitle: "Eco-friendly clothing trends",
    items: [
      {
        title: "Organic",
        number: "Cotton",
      },
      {
        title: "Recycled",
        number: "Polyester",
      },
      {
        title: "Bamboo",
        number: "Fabric",
      },
    ],
  },

  editorial: {
    title: "Content formats",
    subtitle: "Popular media types in 2023",
    items: [
      {
        title: "Reels",
        number: "Video",
      },
      {
        title: "Blogs",
        number: "Text",
      },
      {
        title: "Pods",
        number: "Audio",
      },
    ],
  },

  food: {
    title: "Cuisine hotspots",
    subtitle: "Trending food destinations",
    items: [
      {
        title: "Tokyo",
        number: "Japan",
      },
      {
        title: "Paris",
        number: "France",
      },
      {
        title: "Lima",
        number: "Peru",
      },
    ],
  },

  sport: {
    title: "Fitness tech gear",
    subtitle: "Popular workout gadgets",
    items: [
      {
        title: "Watch",
        number: "$299",
      },
      {
        title: "Bands",
        number: "$49",
      },
      {
        title: "Scale",
        number: "$89",
      },
    ],
  },
} as const satisfies Record<string, UnicornsContentProps>;

export default function Unicorns({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<UnicornsContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      centerH
      px={"md"}
      {...props}
    >
      <Vertical fullW gap={0}>
        <Title variant="card-headline-xs" c={colorScheme.textdark}>
          {content.title}
        </Title>

        <Space h={"xs"} />

        <Text variant="card-paragraph-md" c={colorScheme.textdark}>
          {content.subtitle}
        </Text>

        <Space h={"md"} />

        <Vertical fullW gap={"xs"}>
          {content.items.map((item, index) => (
            <React.Fragment key={item.title}>
              <Horizontal gap={0} fullW spaceBetween noWrap>
                <Text c={colorScheme.textdark} variant="card-label-lg" w={"16"}>
                  {index + 1}
                </Text>

                <Text
                  ml={"xs"}
                  variant={"card-paragraph-md"}
                  c={colorScheme.textdark}
                  flex={1}
                >
                  {item.title}
                </Text>

                <Text c={colorScheme.textdark} variant="card-label-lg">
                  {item.number}
                </Text>
              </Horizontal>

              {index !== content.items.length - 1 && (
                <Divider
                  w={"100%"}
                  color={colorScheme.textdark}
                  opacity={0.5}
                />
              )}
            </React.Fragment>
          ))}
        </Vertical>
      </Vertical>
    </Vertical>
  );
}
