import { createContext, useContext, useEffect, type ReactNode } from "react";
import { openModalLoginIfNeeded } from "../components/ModalLogin";
import { useIsMobile } from "../hooks/useIsMobile";
import { InspoEvent, track } from "../utils";

interface UserContextType {
  user: any;
  isPro: boolean;
  isProd: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({
  children,
  user,
  isPro,
  isProd,
}: {
  children: ReactNode;
  user: any;
  isPro: boolean;
  isProd: boolean;
}) {
  const isMobile = useIsMobile();

  useEffect(() => {
    // need to delay history checkpoint because it's not set otherwise (inside HistorySection)
    setTimeout(() => {
      document.dispatchEvent(new Event(InspoEvent.SET_HISTORY_CHECKPOINT));
    }, 200);
  }, []);

  useEffect(() => {
    openModalLoginIfNeeded({ user, isMobile, source: "app" });
  }, [user]);

  useEffect(() => {
    document.addEventListener(InspoEvent.OPEN_LOGIN_MODAL, () => {
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "card_button",
      });
    });

    return () => {
      document.removeEventListener(InspoEvent.OPEN_LOGIN_MODAL, () => {});
    };
  }, []);

  useEffect(function setupLemonSqueezyListeners() {
    try {
      window.createLemonSqueezy();

      window.LemonSqueezy.Setup({
        eventHandler: (data) => {
          switch (data.event) {
            case "Checkout.Success":
              track({
                event: "checkout_success",
                properties: {},
              });

              fetch("/api/process_payment", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                // @ts-ignore
                body: JSON.stringify(data.data.order),
              });
            case "PaymentMethodUpdate.Mounted":
              console.log(data.event);
            case "PaymentMethodUpdate.Close":
              console.log(data.event);
            case "PaymentMethodUpdate.Updated":
              console.log(data.event);
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!!user) {
      // https://posthog.com/docs/product-analytics/identify
      // @ts-ignore
      window.posthog?.identify(user.id, {
        email: user.email,
        name: user.full_name,
      });

      track({
        event: "home_logged_in",
        properties: { page: "home" },
      });
    } else {
      track({
        event: "home_logged_out",
        properties: { page: "home" },
      });
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, isPro, isProd }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
