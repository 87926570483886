import { Avatar, Text, Tooltip } from "@mantine/core";
import { Vertical } from "../mantine-layout-components/Vertical";

export function InspoSocialProof({}: {}) {
  return (
    <Vertical mx={"auto"} gap={"xxs"}>
      <Text mx={"auto"} variant="paragraph-md">
        Join 6.000+ designers
      </Text>
      <Avatars />
    </Vertical>
  );
}

function Avatars() {
  return (
    <Tooltip.Group openDelay={0} closeDelay={100}>
      <Avatar.Group mx="auto" spacing={6}>
        <Tooltip label="Ahmed">
          <Avatar src="/assets/landing/avatar5.jpg" size={"sm"} />
        </Tooltip>
        <Tooltip label="Taiwo">
          <Avatar src="/assets/landing/avatar4.jpg" size={"sm"} />
        </Tooltip>
        <Tooltip label="Ahmed">
          <Avatar src="/assets/landing/avatar3.jpg" size={"sm"} />
        </Tooltip>
        <Tooltip label="Christina">
          <Avatar src="/assets/landing/avatar2.jpg" size={"sm"} />
        </Tooltip>
        <Tooltip label="Jannic">
          <Avatar src="/assets/landing/avatar1.jpg" size={"sm"} />
        </Tooltip>
      </Avatar.Group>
    </Tooltip.Group>
  );
}
