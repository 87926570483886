import React from "react";
import ArticleHalf, {
  articleHalfContent,
} from "../components/bentoCards/ArticleHalf";
import Authors, { authorsContent } from "../components/bentoCards/Authors";
import {
  BentoHorizontalLg,
  BentoHorizontalMd,
  BentoSquareLg,
  BentoSquareMd,
} from "../components/bentoCards/BentoCards";
import BgPhoto, { bgPhotoContent } from "../components/bentoCards/BgPhoto";
import BlogArticleWithImageLeft, {
  blogArticleWithImageLeftContent,
} from "../components/bentoCards/BlogArticleWithImageLeft";
import BlogArticleWithImageLeftAndTagTop, {
  blogArticleWithImageLeftAndTagTopContent,
} from "../components/bentoCards/BlogArticleWithImageLeftAndTagTop";
import BlogArticleWithImageRight, {
  blogArticleWithImageRightContent,
} from "../components/bentoCards/BlogArticleWithImageRight";
import BlogArticleWithImageTop, {
  blogArticleWithImageTopContent,
} from "../components/bentoCards/BlogArticleWithImageTop";
import Cart, { cartContent } from "../components/bentoCards/Cart";
import EcommItem, {
  ecommItemContent,
} from "../components/bentoCards/EcommItem";
import HeaderBackgroundHalf, {
  headerBackgroundHalfContent,
} from "../components/bentoCards/HeaderBackgroundHalf";
import HeaderHalf, {
  headerHalfContent,
} from "../components/bentoCards/HeaderHalf";
import HeaderImageButton, {
  headerImageButtonContent,
} from "../components/bentoCards/HeaderImageButton";
import HeadingWithBgColor, {
  headingWithBgColorContent,
} from "../components/bentoCards/HeadingWithBgColor";
import HeadingWithBgPhoto, {
  headingWithBgPhotoContent,
} from "../components/bentoCards/HeadingWithBgPhoto";
import HeadingWithBgPhotoLeft, {
  headingWithBgPhotoLeftContent,
} from "../components/bentoCards/HeadingWithBgPhotoLeft";
import IconsGrid, {
  iconsGridContent,
} from "../components/bentoCards/IconsGrid";
import ImageLaunch, {
  imagesLaunchContent,
} from "../components/bentoCards/ImageLaunch";
import ImageLeft, {
  imageLeftContent,
} from "../components/bentoCards/ImageLeft";
import LaunchText, {
  launchTextContent,
} from "../components/bentoCards/LaunchText";
import MenuPdfs, { menuPdfsContent } from "../components/bentoCards/MenuPdfs";
import ProductSingle, {
  productSingleContent,
} from "../components/bentoCards/ProductSingle";
import Savings, { savingsContent } from "../components/bentoCards/Savings";
import TagsGrid, { tagsGridContent } from "../components/bentoCards/TagsGrid";
import TextLinesWithUpdatesTag, {
  textLinesWithUpdatesTagContent,
} from "../components/bentoCards/TextLinesWithUpdatesTag";
import TitleWithBgColor, {
  titleWithBgColorContent,
} from "../components/bentoCards/TitleWithBgColor";
import Unicorns, { unicornsContent } from "../components/bentoCards/Unicorns";
import { useCardsState } from "../contexts/CardsStateContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useIndustry } from "../contexts/IndustryContext";
import LaunchDoubleTitle, {
  launchDoubleTitleContent,
} from "../components/bentoCards/LaunchDoubleTitle";

// https://ulidgenerator.com/

export function useBentoCards() {
  const { industry } = useIndustry();
  const { currentColorScheme: colorScheme } = useColorPalette();
  const { visibleCardsKeys } = useCardsState();

  function getVisibleChildrenCard({ cards }: { cards?: React.ReactNode[] }) {
    const visibleCard = cards?.find((card) => {
      return (
        React.isValidElement(card) &&
        card.key !== null &&
        visibleCardsKeys?.includes(card.key)
      );
    });

    if (
      !visibleCardsKeys ||
      !visibleCardsKeys.length ||
      !React.isValidElement(visibleCard)
    ) {
      const firstCard = cards?.[0] as React.ReactElement;
      return React.cloneElement(firstCard, {
        alternativeCardsKeys: cards?.map((c) => c?.["key"]).filter(Boolean),
      });
    }

    const alternativeCardsKeys = cards?.map((c) =>
      React.isValidElement(c) ? c.key : null,
    );
    return React.cloneElement(visibleCard, {
      // @ts-ignore
      alternativeCardsKeys: alternativeCardsKeys?.filter(Boolean) as string[],
    });
  }

  const bentoCards = {
    ecommerce: [
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <EcommItem
              key={"a1b2c3d4e5"}
              colorScheme={colorScheme.dark}
              content={ecommItemContent.fashion}
            />,
            <HeaderHalf
              key={"01JADA2F90AAGP016BH2TA04SB"}
              colorScheme={colorScheme.dark}
              content={headerHalfContent.fashion}
            />,
            <ImageLeft
              key={"01JAF9NJF8EP86MC0TVPRCEWWM"}
              colorScheme={colorScheme.dark}
              content={imageLeftContent.fashion}
            />,
            <HeadingWithBgPhoto
              key={"01JAFHV5RVAYQQRNC6CM931JY0"}
              colorScheme={colorScheme.dark}
              content={headingWithBgPhotoContent.fashion}
            />,
            <BlogArticleWithImageLeft
              key={"01JAFJ4KW5KGXJ8TJCD4A7YJB3"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageLeftContent.fashion}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JAGCG7X6S48V8QP62S0635CS"}
              colorScheme={colorScheme.dark}
              content={headingWithBgPhotoLeftContent.fashion}
            />,
            // Card W.I.P.
            // <HeaderTwoColumns
            //   key={"01JAJP2TJSCQR8FWYZC99GPRC3"}
            //   colorScheme={colorScheme.dark}
            //   content={HeaderTwoColumnsContent.fashion}
            // />,
            <HeaderBackgroundHalf
              key={"01JAJVBG3N7B7EYH2NNEGT4VSB"}
              colorScheme={colorScheme.dark}
              content={headerBackgroundHalfContent.fashion}
            />,
            <ArticleHalf
              key={"01JAJZ9V1K5ZY0TNA33NMYAHZZ"}
              colorScheme={colorScheme.dark}
              content={articleHalfContent.fashion}
            />,
            <HeaderImageButton
              key={"01JAK14ZYQ59PZYSQE5BHDBF47"}
              colorScheme={colorScheme.dark}
              content={headerImageButtonContent.fashion}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Cart
              key={"f6g7h8i9j0"}
              colorScheme={colorScheme.bright}
              content={cartContent.fashion}
            />,
            <TextLinesWithUpdatesTag
              key={"01JA5V7HNB6YE9TEM54FNKKJTV"}
              colorScheme={colorScheme.bright}
              content={textLinesWithUpdatesTagContent.fashion}
            />,
            <BlogArticleWithImageTop
              key={"334ba411"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageTopContent.fashion}
            />,
            <MenuPdfs
              key={"01JACY58S1ZM5ZWVVKVR7J5ZA4"}
              colorScheme={colorScheme.bright}
              content={menuPdfsContent.fashion}
            />,
            <ImageLaunch
              key={"01JBKN78TR9M1ZY4Y2PMXDFMMW"}
              colorScheme={colorScheme.bright}
              content={imagesLaunchContent.fashion}
            />,
            <ProductSingle
              key={"01JACYRH8FDA8520PBG583RREC"}
              colorScheme={colorScheme.bright}
              content={productSingleContent.fashion}
            />,
            <LaunchDoubleTitle
              key={"01JBYVGEJTQXEJ0G63RWAXVKZH"}
              colorScheme={colorScheme.bright}
              content={launchDoubleTitleContent.fashion}
            />,
            <Unicorns
              key={"01JACZ05555555555555555555"}
              colorScheme={colorScheme.bright}
              content={unicornsContent.fashion}
            />,
            <BgPhoto
              key={"01JBKJMFGY00W7ZYZ6SFX4VN22"}
              colorScheme={colorScheme.bright}
              content={bgPhotoContent.fashionLandscape}
            />,
            <LaunchText
              key={"01JBVVY1W3VZ3JCRT4P3MKXGFD"}
              colorScheme={colorScheme.bright}
              content={launchTextContent.fashion}
            />,
            // Card W.I.P
            // <LaunchImage
            // key={"01JBW7GPRKBPV44AA9KJKDMEE2"}
            // colorScheme={colorScheme.bright}
            // content={launchImageContent.fashion}
            // />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"k1l2m3n4o5"}
              colorScheme={colorScheme.light}
              content={titleWithBgColorContent.fashion}
            />,
            <HeadingWithBgColor
              key={"80e696ca"}
              colorScheme={colorScheme.light}
              content={headingWithBgColorContent.fashion}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"p6q7r8s9t0"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <TextLinesWithUpdatesTag
              key={"01JBXYWT4DE4DS7KSZABAVCZNH"}
              colorScheme={colorScheme.light}
              content={textLinesWithUpdatesTagContent.fashion}
            />,
            <BlogArticleWithImageTop
              key={"01JBXYX158PQNZTPMBEQHBPK1M"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageTopContent.fashion}
            />,
            <MenuPdfs
              key={"01JBXYX93VQE02AC1QFCJK6NS6"}
              colorScheme={colorScheme.light}
              content={menuPdfsContent.fashion}
            />,
            <ImageLaunch
              key={"01JBXYXHPZTEE24DE2JZY4FBYK"}
              colorScheme={colorScheme.light}
              content={imagesLaunchContent.fashion}
            />,
            <ProductSingle
              key={"01JBXYXQ5E20C9RV71M36BGCGY"}
              colorScheme={colorScheme.light}
              content={productSingleContent.fashion}
            />,

            <Unicorns
              key={"01JBXYXV0YJ4M1JXK473REP1HX"}
              colorScheme={colorScheme.light}
              content={unicornsContent.fashion}
            />,
            <BgPhoto
              key={"01JBXYXZSY20SCA96DJEA5B92H"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionLandscape}
            />,
            <LaunchText
              key={"01JBXYY3VQR7YRFRBT158XKD9X"}
              colorScheme={colorScheme.light}
              content={launchTextContent.fashion}
            />,
            <Cart
              key={"01JBXYY8CGYE121Z70VNREA3W1"}
              colorScheme={colorScheme.light}
              content={cartContent.fashion}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"z6a7b8c9d0"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconFashion}
            />,
            <BgPhoto
              key={"01JBXZTZMZZ1QSMZ2T9XHYN7RN"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionSquare}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"e1f2g3h4i5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionSquare}
            />,
            <IconsGrid
              key={"01JBXZS01R9MM2M7EPFDAMZWP4"}
              colorScheme={colorScheme.light}
              content={iconsGridContent.iconFashion}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeaderHalf
              key={"01JBW7B5F4V7STM85RB5M4KXY6"}
              colorScheme={colorScheme.dark}
              content={headerHalfContent.fashion}
            />,
            <ImageLeft
              key={"01JBW7BF6R6KB3RYEQ1GYJVMFP"}
              colorScheme={colorScheme.dark}
              content={imageLeftContent.fashion}
            />,
            <HeadingWithBgPhoto
              key={"01JBW7BKAJVQWY69X4MY49J63E"}
              colorScheme={colorScheme.dark}
              content={headingWithBgPhotoContent.fashion}
            />,
            <BlogArticleWithImageLeft
              key={"01JBW7BQDEWHEN8G4ZK9YXC802"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageLeftContent.fashion}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBW7BVQT9X808BFAZ2XZCJTH"}
              colorScheme={colorScheme.dark}
              content={headingWithBgPhotoLeftContent.fashion}
            />,
            // <HeaderTwoColumns
            //   key={"01JBW7BZTMMSDVY3QVP7FTGFA0"}
            //   colorScheme={colorScheme.dark}
            //   content={HeaderTwoColumnsContent.fashion}
            // />,
            <HeaderBackgroundHalf
              key={"01JBW7C4WDWN7DH9Q9GR89AFEM"}
              colorScheme={colorScheme.dark}
              content={headerBackgroundHalfContent.fashion}
            />,
            <ArticleHalf
              key={"01JBW7C8PQCH1Q20QA7YDM5Y34"}
              colorScheme={colorScheme.dark}
              content={articleHalfContent.fashion}
            />,
            <HeaderImageButton
              key={"01JBW7CCZA635GEZZ1PVGB4R34"}
              colorScheme={colorScheme.dark}
              content={headerImageButtonContent.fashion}
            />,
            <EcommItem
              key={"01JBW7CHA4H58Y1VH368131PG8"}
              colorScheme={colorScheme.dark}
              content={ecommItemContent.fashion}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"01JBXZEJMF1273XHASRVXB9R7V"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.fashionLandscape}
            />,
          ],
        })}
      />,
    ],

    editorial: [
      //First Row
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"a1b2c3d4e4"}
              colorScheme={colorScheme.bright}
              content={titleWithBgColorContent.editorial}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
            <HeadingWithBgColor
              key={"b6ba"}
              colorScheme={colorScheme.bright}
              content={headingWithBgColorContent.editorial}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Authors
              key={"01JBYE86HE0K4ZDEB57ETZPR5C"}
              colorScheme={colorScheme.dark}
              content={authorsContent.editorial}
            />,
            <BlogArticleWithImageTop
              key={"01JBYE8AY77BPVN072JT27GB3A"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageTopContent.editorial}
            />,
            <Cart
              key={"01JBYE8EX4H07GKXRJ3Z1JBJC3"}
              colorScheme={colorScheme.dark}
              content={cartContent.editorial}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYE8J9V52KZPQPHT4NFK1AH"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.editorial}
            />,
            <ImageLaunch
              key={"01JBYE8PTWA7R0BY3Y2BFWQBWD"}
              colorScheme={colorScheme.dark}
              content={imagesLaunchContent.editorial}
            />,
            <ProductSingle
              key={"01JBYE8T0FEV22TF2N6E8G5R28"}
              colorScheme={colorScheme.dark}
              content={productSingleContent.editorial}
            />,
            <Unicorns
              key={"01JBYE8XE1WSXC8T8RH4TNC5G7"}
              colorScheme={colorScheme.dark}
              content={unicornsContent.editorial}
            />,
            <BgPhoto
              key={"01JBYE90WNGATVXXH0X9CYF7A2"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.editorialLandscape}
            />,
            <MenuPdfs
              key={"01JBYE95GD5NRX2ERT9WXE5QZN"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.editorial}
            />,
            <LaunchText
              key={"01JBYE99BSY2ATB14123FYBQN0"}
              colorScheme={colorScheme.dark}
              content={launchTextContent.editorial}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeftAndTagTop
              key={"01JBYTWP1D1MQRGC5BF6V3R8M2"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftAndTagTopContent.editorial}
            />,
            <BlogArticleWithImageLeft
              key={"ccd57935"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.editorial}
            />,
            <HeaderHalf
              key={"01JBYTRAMN6KMTJTSRX74SRZNY"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.editorial}
            />,
            <ImageLeft
              key={"01JBYTX3627EEYK96Z03GJCWYS"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.editorial}
            />,
            <HeadingWithBgPhoto
              key={"01JBYTY46RR34X47VDJVJ7CY07"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.editorial}
            />,
            <HeaderBackgroundHalf
              key={"01JBYTYPW1W7YNW4HM4RWMVYR1"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.editorial}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYTZHQ9AC4HJZCE8WBFGMG9"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.editorial}
            />,
            <ArticleHalf
              key={"01JBYV00C9WR2KKYF18T9A3EJR"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.editorial}
            />,
            <HeaderImageButton
              key={"01JBYV0CP7V9BFQ953S3N4CP70"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.editorial}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"01JBYV0X20956X4NDJV6FKBD9X"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialLandscape}
            />,
          ],
        })}
      />,
      //Second Row
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"u1v2w3x4y5"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.editorial}
            />,
            <BlogArticleWithImageLeftAndTagTop
              key={"01JBYDJJHEBFJXHAQNBB0FS7V5"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftAndTagTopContent.editorial}
            />,
            <BlogArticleWithImageLeft
              key={"01JBYDJPB0T75W5AANY91DM1C7"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.editorial}
            />,
            <HeaderHalf
              key={"01JBYDJSHKYTTR2704V0GCJ751"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.editorial}
            />,
            <ImageLeft
              key={"01JBYDJXPNEQ5D8B460CAKRVSC"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.editorial}
            />,
            <HeadingWithBgPhoto
              key={"01JBYDK1GP6B9NGCX1SFQVFX1K"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.editorial}
            />,
            <HeaderBackgroundHalf
              key={"01JBYDK5721S2AKVE0N2BBG7VN"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.editorial}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYDK9HZT68ME3AY578BVAQG"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.editorial}
            />,
            <ArticleHalf
              key={"01JBYDKDD7TAVNYF5PFX9VA7B9"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.editorial}
            />,
            <HeaderImageButton
              key={"01JBYDKHGEZ97CCC7ZZQZ2DSAJ"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.editorial}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <TagsGrid
              key={"01JBYV1621CZN9KF8QAQK18D4S"}
              colorScheme={colorScheme.bright}
              content={tagsGridContent.editorial}
            />,
            <Authors
              key={"01JBYF1K1YNNS58XAYGYHZ630P"}
              colorScheme={colorScheme.bright}
              content={authorsContent.editorial}
            />,
            <BlogArticleWithImageTop
              key={"01JBYF1TFH2HDVWS55AZ35ZM0T"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageTopContent.editorial}
            />,
            <Cart
              key={"01JBYF1Y3FB2FZ3JNWG13A1GCN"}
              colorScheme={colorScheme.bright}
              content={cartContent.editorial}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYF21B3716B66208R4NP686"}
              colorScheme={colorScheme.bright}
              content={textLinesWithUpdatesTagContent.editorial}
            />,
            <ImageLaunch
              key={"01JBYF24TXWRTTH8NBW6E4Y013"}
              colorScheme={colorScheme.bright}
              content={imagesLaunchContent.editorial}
            />,
            <ProductSingle
              key={"01JBYF28Z078A8VK24ZW5AHGAS"}
              colorScheme={colorScheme.bright}
              content={productSingleContent.editorial}
            />,
            <Unicorns
              key={"01JBYF2D1A570KB38311WN5PFZ"}
              colorScheme={colorScheme.bright}
              content={unicornsContent.editorial}
            />,
            <BgPhoto
              key={"01JBYF2JYSSJZAVSGDZV3TGH8H"}
              colorScheme={colorScheme.bright}
              content={bgPhotoContent.editorialLandscape}
            />,
            <MenuPdfs
              key={"01JBYF2PZNESH107Z1Q8CNRPR0"}
              colorScheme={colorScheme.bright}
              content={menuPdfsContent.editorial}
            />,
            <LaunchText
              key={"01JBYF2TSFHVK52KK0HYTNBQHE"}
              colorScheme={colorScheme.bright}
              content={launchTextContent.editorial}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"01JBYV1D8P73Q48CYE80RQKDYN"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialSquare}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"j6k7l8m9n0"}
              colorScheme={colorScheme.dark}
              content={iconsGridContent.iconEditorial}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"o1p2q3r4s5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialLandscape}
            />,
          ],
        })}
      />,
    ],

    finance: [
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"t6u7v8w9x0"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.finance}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
            <HeadingWithBgColor
              key={"01JBYV1NY138C1299MRBDX2385"}
              colorScheme={colorScheme.dark}
              content={headingWithBgColorContent.finance}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Unicorns
              key={"y1z2a3b4c5"}
              colorScheme={colorScheme.bright}
              content={unicornsContent.finance}
            />,
            <Cart
              key={"01JBYV2JWD1JC331D4G5DE62SG"}
              colorScheme={colorScheme.bright}
              content={cartContent.finance}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYV2TKM80EPV8PCX2RM8YHH"}
              colorScheme={colorScheme.bright}
              content={textLinesWithUpdatesTagContent.finance}
            />,
            <ImageLaunch
              key={"01JBYV310SZTNHYQGA74V17PXB"}
              colorScheme={colorScheme.bright}
              content={imagesLaunchContent.finance}
            />,
            <ProductSingle
              key={"01JBYV37W7NCX2R765XRMNZ8DH"}
              colorScheme={colorScheme.bright}
              content={productSingleContent.finance}
            />,
            <BgPhoto
              key={"01JBYV3HB6MESPAC62MM3YNYPD"}
              colorScheme={colorScheme.bright}
              content={bgPhotoContent.financeLandscape}
            />,
            <MenuPdfs
              key={"01JBYV3Q6TW3BSB5JFEEAG4RFA"}
              colorScheme={colorScheme.bright}
              content={menuPdfsContent.finance}
            />,
            <LaunchText
              key={"01JBYV425NAQ7NA9CWDMH1YH8J"}
              colorScheme={colorScheme.bright}
              content={launchTextContent.finance}
            />,
            <Savings
              key={"01JBYG7JRN5X0GSQN5NHRAETTD"}
              colorScheme={colorScheme.bright}
              content={savingsContent.finance}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"d6e7f8g9h0"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.finance}
            />,
            <BlogArticleWithImageLeft
              key={"01JBYV493T96T4VDM2J1MJJ0MD"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.finance}
            />,
            <HeaderHalf
              key={"01JBYV4MEXJM566JYBP4J95FPH"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.finance}
            />,
            <ImageLeft
              key={"01JBYV4XY9B8HHS4ZY89E6PDTB"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.finance}
            />,
            <HeaderBackgroundHalf
              key={"01JBYV55Y27A3FJWBSDP7GFDHZ"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.finance}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYV5D7DF3BFKWPCB8JHQ0EE"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.finance}
            />,
            <ArticleHalf
              key={"01JBYV5M1CNQ98MGWG28PM1XCD"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.finance}
            />,
            <HeaderImageButton
              key={"01JBYV5VDE2459NY48QRRZQT04"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.finance}
            />,
            <BlogArticleWithImageRight
              key={"01JBYGHVDAFDJ7TM5QBZ8AXHQE"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageRightContent.finance}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"i1j2k3l4m5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageRight
              key={"01JBYGWHGEQ8FQ314940FEHKR3"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageRightContent.finance}
            />,
            <HeadingWithBgPhoto
              key={"01JBYGWMQG6PKB8M2JD1H5W1JT"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.finance}
            />,
            <BlogArticleWithImageLeft
              key={"01JBYGWQZVCKKKRQDWJTXWTN8X"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.finance}
            />,
            <HeaderHalf
              key={"01JBYGXD7B3DGFC7F74FX2R0CC"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.finance}
            />,
            <ImageLeft
              key={"01JBYGXGW57AJHGE95ATYE3W6J"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.finance}
            />,
            <HeaderBackgroundHalf
              key={"01JBYGXN82WQSN4DX3RSA82D31"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.finance}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYGXT2MJFY66RWBRTNARE0C"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.finance}
            />,
            <ArticleHalf
              key={"01JBYGXYV7YT8HWR372GM12292"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.finance}
            />,
            <HeaderImageButton
              key={"01JBYGY2G1223036TRKTRA7JJD"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.finance}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"s1t2u3v4w5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeSquare}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"x6y7z8a9b0"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconFinance}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Savings
              key={"01JBYG5GR3M6WWQESFRV54VXAP"}
              colorScheme={colorScheme.dark}
              content={savingsContent.finance}
            />,
            <Unicorns
              key={"01JBYG5MZW90A0YCP1FNB4DCCF"}
              colorScheme={colorScheme.dark}
              content={unicornsContent.finance}
            />,
            <Cart
              key={"01JBYG5R9N4V1YTKDE5MT953VV"}
              colorScheme={colorScheme.dark}
              content={cartContent.finance}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYG5WA6WYZM4H4RNN07TYCR"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.finance}
            />,
            <ImageLaunch
              key={"01JBYG5ZVBGMP29HGZMWW456MQ"}
              colorScheme={colorScheme.dark}
              content={imagesLaunchContent.finance}
            />,
            <ProductSingle
              key={"01JBYG63PFDS5JA1RX9NG5G6FN"}
              colorScheme={colorScheme.dark}
              content={productSingleContent.finance}
            />,
            <BgPhoto
              key={"01JBYG67VJNPNHJG9NZT3CG0HG"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.financeLandscape}
            />,
            <MenuPdfs
              key={"01JBYG6BMDA6QMKZAA8CPY2MVC"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.finance}
            />,
            <LaunchText
              key={"01JBYG6FKHKNYTJ7D4K6B4NBC1"}
              colorScheme={colorScheme.dark}
              content={launchTextContent.finance}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"h6i7j8k9l0"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeLandscape}
            />,
          ],
        })}
      />,
    ],

    foodNDrink: [
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageTop
              key={"01JBYV625KDTJGJSH2AGMBEG5P"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageTopContent.food}
            />,
            <Cart
              key={"01JBYV74FFW3KV81A1V22SMAB7"}
              colorScheme={colorScheme.light}
              content={cartContent.food}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYV7H5303JPWPRZJ1V7MH99"}
              colorScheme={colorScheme.light}
              content={textLinesWithUpdatesTagContent.food}
            />,
            <ImageLaunch
              key={"01JBYV7YJRX16CBKTWF5NTA26E"}
              colorScheme={colorScheme.light}
              content={imagesLaunchContent.food}
            />,
            <ProductSingle
              key={"01JBYV8BBB3RPZV4XBXEV3MP8M"}
              colorScheme={colorScheme.light}
              content={productSingleContent.food}
            />,
            <Unicorns
              key={"01JBYV8JBTZD9Z41ZRYDCM3YH5"}
              colorScheme={colorScheme.light}
              content={unicornsContent.food}
            />,
            <BgPhoto
              key={"01JBYV8YPXJMWADH3Z13T22X59"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.foodLandscape}
            />,
            <MenuPdfs
              key={"01JBYV99DX36W4E7WTE4061RGP"}
              colorScheme={colorScheme.light}
              content={menuPdfsContent.food}
            />,
            <LaunchText
              key={"01JBYV9NBKT14X2Z4RFB0YCRWY"}
              colorScheme={colorScheme.light}
              content={launchTextContent.food}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"e022d8b3"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.food}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
            <BgPhoto
              key={"01JBY0MJ95WZ4SXCECCC3K0JM2"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.foodLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"01JBYV9Z099EV6THDXQJC5J5R3"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.food}
            />,
            <EcommItem
              key={"a1b2c3d4e3"}
              colorScheme={colorScheme.bright}
              content={ecommItemContent.food}
            />,
            <HeaderHalf
              key={"01JBYTRWVJDF6E4KHYSJEBVDCT"}
              colorScheme={colorScheme.bright}
              content={headerHalfContent.food}
            />,
            <ImageLeft
              key={"01JBYTXPSW22VYG2ZYFDHQ6ACE"}
              colorScheme={colorScheme.bright}
              content={imageLeftContent.food}
            />,
            <HeadingWithBgPhoto
              key={"01JBYTYB6XVH4808DDAC1R7W03"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoContent.food}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYTZQD018VZNGDGHZ7F76HV"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoLeftContent.food}
            />,
            <HeaderBackgroundHalf
              key={"01JBYTYWDRBSDGDGZ84Z61KF1T"}
              colorScheme={colorScheme.bright}
              content={headerBackgroundHalfContent.food}
            />,
            <ArticleHalf
              key={"01JBYV04PV0YKDS2ERR84E8BK4"}
              colorScheme={colorScheme.bright}
              content={articleHalfContent.food}
            />,
            <HeaderImageButton
              key={"01JBYV0J9ER22PH8S4TT0G2AGW"}
              colorScheme={colorScheme.bright}
              content={headerImageButtonContent.food}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"3f7364ca"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.foodLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"48a9"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.food}
            />,
            <BlogArticleWithImageLeft
              key={"01JBY39EZRKD99SYD650XZMHTA"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.food}
            />,
            <EcommItem
              key={"01JBY39VQQETYNVRXNNTC3SGR3"}
              colorScheme={colorScheme.bright}
              content={ecommItemContent.food}
            />,
            <HeaderHalf
              key={"01JBY3EWK0YDJ03WTC0X3NCTDD"}
              colorScheme={colorScheme.bright}
              content={headerHalfContent.food}
            />,
            <ImageLeft
              key={"01JBY3F1MNB27VP8BDFTN0H6JY"}
              colorScheme={colorScheme.bright}
              content={imageLeftContent.food}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBY3F5ESGN4D442KEMSGATEF"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoLeftContent.food}
            />,
            <HeaderBackgroundHalf
              key={"01JBY3FC1EDK34XQZDZP8BC7FY"}
              colorScheme={colorScheme.bright}
              content={headerBackgroundHalfContent.food}
            />,
            <HeaderImageButton
              key={"01JBY3FKZZA8EEWRGERYXCKAJP"}
              colorScheme={colorScheme.bright}
              content={headerImageButtonContent.food}
            />,
            <ArticleHalf
              key={"01JBY3FG62C1ZTBA57TKZAYXA2"}
              colorScheme={colorScheme.bright}
              content={articleHalfContent.food}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <MenuPdfs
              key={"4511"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.food}
            />,
            <BlogArticleWithImageTop
              key={"01JBY8F1YDRYP6FR29JX3W0XWD"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageTopContent.food}
            />,
            <Cart
              key={"01JBY8F9MTT74TJGE1BZ2QRZB8"}
              colorScheme={colorScheme.dark}
              content={cartContent.food}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBY8FDG2NQR7ZMDE2QSMFBCA"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.food}
            />,
            <ImageLaunch
              key={"01JBY8FHGMX7KE3HB1M87FWMBQ"}
              colorScheme={colorScheme.dark}
              content={imagesLaunchContent.food}
            />,
            <ProductSingle
              key={"01JBY8FNBN8FKNVPHPCYNK0GTF"}
              colorScheme={colorScheme.dark}
              content={productSingleContent.food}
            />,
            <Unicorns
              key={"01JBY8FRS7KVGB5T260CDPJF4K"}
              colorScheme={colorScheme.dark}
              content={unicornsContent.food}
            />,
            <BgPhoto
              key={"01JBY8FX2JDHNC4Z96TX5XR88Q"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.foodLandscape}
            />,
            <LaunchText
              key={"01JBY8G1Q26DTW9TK8BQAGVTD4"}
              colorScheme={colorScheme.dark}
              content={launchTextContent.food}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgColor
              key={"01JBYV1VPRXKH7HYDXEWZYWTDN"}
              colorScheme={colorScheme.light}
              content={headingWithBgColorContent.food}
            />,
            <TitleWithBgColor
              key={"01JBY0NZ1PEQR6XBSCZHWRZYTY"}
              colorScheme={colorScheme.light}
              content={titleWithBgColorContent.food}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"837e4801a790"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconFood}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"c651f984"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.foodSquare}
            />,
          ],
        })}
      />,
    ],

    sport: [
      // First Row
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"e4852c84"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <MenuPdfs
              key={"01JBYTWA03JKHVDNNZRYSKGPM8"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.sport}
            />,
            <BlogArticleWithImageTop
              key={"01JBYVA81E5CF624NDHSP020X2"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageTopContent.sport}
            />,
            <Cart
              key={"01JBYVAFMJGQBD7YA265YTZ1ME"}
              colorScheme={colorScheme.dark}
              content={cartContent.sport}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYVAQZEDVCY8CB0NV2YCF8R"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.sport}
            />,
            <ImageLaunch
              key={"01JBYVAZP2PJ2XCBFQJGJ38S72"}
              colorScheme={colorScheme.dark}
              content={imagesLaunchContent.sport}
            />,
            <ProductSingle
              key={"01JBYVB6TGWMA26ETWZKJ58HJ7"}
              colorScheme={colorScheme.dark}
              content={productSingleContent.sport}
            />,
            <Unicorns
              key={"01JBYVBEXKYF216D3NE5EVY64A"}
              colorScheme={colorScheme.dark}
              content={unicornsContent.sport}
            />,
            <BgPhoto
              key={"01JBYVBP7QWCEB28TVQ13T9N5D"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.sportLandscape}
            />,
            <LaunchText
              key={"01JBYVBXGB9YX68V7TD1JT5X01"}
              colorScheme={colorScheme.dark}
              content={launchTextContent.sport}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"01JBYKMYK4T0X2CT9ACEFM9SFH"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.sport}
            />,
            <BlogArticleWithImageLeft
              key={"01JBYKN63PG8TG7MC3YXGG8BJT"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.sport}
            />,
            <EcommItem
              key={"01JBYKNEP26B4225RG5XMBRPHW"}
              colorScheme={colorScheme.light}
              content={ecommItemContent.sport}
            />,
            <HeaderHalf
              key={"01JBYKNK7N9AZCW6F15C48DZZC"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.sport}
            />,
            <ImageLeft
              key={"01JBYKNPMD2RYVMY9DW8N98Z4N"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.sport}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYKP82ZXSR8QSFVZWM85EQ3"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.sport}
            />,
            <HeaderBackgroundHalf
              key={"01JBYKPCKR481PZNVKCPXTV24P"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.sport}
            />,
            <HeaderImageButton
              key={"01JBYKPGCPWCJXZS5C8QY2Q1WQ"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.sport}
            />,
            <ArticleHalf
              key={"01JBYKPM0F80D5G1KM3TQ9CMWD"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.sport}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"6f615f18"}
              colorScheme={colorScheme.bright}
              content={titleWithBgColorContent.sport}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
            <HeadingWithBgColor
              key={"01JBYJ6WDXSQ841QEGVCM79NT9"}
              colorScheme={colorScheme.bright}
              content={headingWithBgColorContent.sport}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"01JBYKSK9Q1HQRND1S774E1FAG"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.sport}
            />,
            <EcommItem
              key={"01JBYKSPR7PQT8YNRERNMMFYYN"}
              colorScheme={colorScheme.light}
              content={ecommItemContent.sport}
            />,
            <HeaderHalf
              key={"01JBYKSTDHX07DW1JDHHA5JB35"}
              colorScheme={colorScheme.light}
              content={headerHalfContent.sport}
            />,
            <ImageLeft
              key={"01JBYKSXVDBKAMN228D2SEVX4G"}
              colorScheme={colorScheme.light}
              content={imageLeftContent.sport}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYKT19Z8Y57VS4PDNXB0VKZ"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoLeftContent.sport}
            />,
            <HeaderBackgroundHalf
              key={"01JBYKT57YMW2PDK7TWG13CAWW"}
              colorScheme={colorScheme.light}
              content={headerBackgroundHalfContent.sport}
            />,
            <HeaderImageButton
              key={"01JBYKT8GHXEP8P9Q3525T3DN7"}
              colorScheme={colorScheme.light}
              content={headerImageButtonContent.sport}
            />,
            <ArticleHalf
              key={"01JBYKTCAB84GA9ZAP5P0XJCCA"}
              colorScheme={colorScheme.light}
              content={articleHalfContent.sport}
            />,
            <HeadingWithBgPhoto
              key={"01JBYKSG2HD5B9JGCBGCXZ0S8X"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.sport}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"9a83dd9s"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Cart
              key={"01JBYJPE9N6SANMYQVBXP0EVQJ"}
              colorScheme={colorScheme.bright}
              content={cartContent.sport}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYJPHDGSG8E6SHMTT6V61HZ"}
              colorScheme={colorScheme.bright}
              content={textLinesWithUpdatesTagContent.sport}
            />,
            <MenuPdfs
              key={"01JBYJP5AWZP9YWEZR69YH88C1"}
              colorScheme={colorScheme.bright}
              content={menuPdfsContent.sport}
            />,
            <ImageLaunch
              key={"01JBYJPN0CF7DQMS26Q3BZEM14"}
              colorScheme={colorScheme.bright}
              content={imagesLaunchContent.sport}
            />,
            <ProductSingle
              key={"01JBYJPRZ01HDCT4E7PMZ5R3HM"}
              colorScheme={colorScheme.bright}
              content={productSingleContent.sport}
            />,
            <Unicorns
              key={"01JBYJPYJ9D67GHGTN2A7PAYF0"}
              colorScheme={colorScheme.bright}
              content={unicornsContent.sport}
            />,
            <BgPhoto
              key={"01JBYJQ252Y73X6YT16MNJEDPA"}
              colorScheme={colorScheme.bright}
              content={bgPhotoContent.sportLandscape}
            />,
            <LaunchText
              key={"01JBYJQ69ZM1B9EN9ASRHT2Z2S"}
              colorScheme={colorScheme.bright}
              content={launchTextContent.sport}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"3e8eeaca"}
              colorScheme={colorScheme.dark}
              content={iconsGridContent.iconSport}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"15a2f581"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportSquare}
            />,
          ],
        })}
      />,
    ],

    beauty: [
      //First Row
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"bcb7fa7d"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.beauty}
            />,
            <HeadingWithBgPhoto
              key={"01JBYVC57RX3CJB6SNVX1HRQ43"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoContent.beauty}
            />,
            <EcommItem
              key={"01JBYVCD6MCPP73PJB8E3TP2YP"}
              colorScheme={colorScheme.bright}
              content={ecommItemContent.beauty}
            />,
            <HeaderHalf
              key={"01JBYVCR4WDNBP0HNKE9G05XWW"}
              colorScheme={colorScheme.bright}
              content={headerHalfContent.beauty}
            />,
            <ImageLeft
              key={"01JBYVCYN9QJNCJ4PJ0BWJ1G3M"}
              colorScheme={colorScheme.bright}
              content={imageLeftContent.beauty}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYVD5M6BSKX23QSRG8NQ4AK"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoLeftContent.beauty}
            />,
            <HeaderBackgroundHalf
              key={"01JBYVDDAYTDWJXVZH11G3PEA5"}
              colorScheme={colorScheme.bright}
              content={headerBackgroundHalfContent.beauty}
            />,
            <HeaderImageButton
              key={"01JBYVDNRYDHP6WC35XCTBCB1H"}
              colorScheme={colorScheme.bright}
              content={headerImageButtonContent.beauty}
            />,
            <ArticleHalf
              key={"01JBYVDYAGRFPDEDZW1A4NBQDM"}
              colorScheme={colorScheme.bright}
              content={articleHalfContent.beauty}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"01JBYVECSFP0NRMMAW5HEFSH9Q"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.beauty}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
            <HeadingWithBgColor
              key={"01JBYV27WZF87VW8A1646EGYCZ"}
              colorScheme={colorScheme.dark}
              content={headingWithBgColorContent.beauty}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <ProductSingle
              key={"e8c1ac6da873"}
              colorScheme={colorScheme.light}
              content={productSingleContent.beauty}
            />,
            <BlogArticleWithImageTop
              key={"01JBYV68AHC9FSY0GT43GWQQCH"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageTopContent.beauty}
            />,
            <Cart
              key={"01JBYV780BXGVAHDA605K1ECSG"}
              colorScheme={colorScheme.light}
              content={cartContent.beauty}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYV7QFJ6YJ5AEEAJF4Q8BCZ"}
              colorScheme={colorScheme.light}
              content={textLinesWithUpdatesTagContent.beauty}
            />,
            <ImageLaunch
              key={"01JBYV83RFMVAC46KAG13K5VGX"}
              colorScheme={colorScheme.light}
              content={imagesLaunchContent.beauty}
            />,
            <Unicorns
              key={"01JBYV8Q443T6YQ9C306MDD7Y6"}
              colorScheme={colorScheme.light}
              content={unicornsContent.beauty}
            />,
            <BgPhoto
              key={"01JBYV92RGX8MGKXHQPAWWB1KG"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.beautyLandscape}
            />,
            <MenuPdfs
              key={"01JBYV9E0FEV57GHWMX4HMZY34"}
              colorScheme={colorScheme.light}
              content={menuPdfsContent.beauty}
            />,
            <LaunchText
              key={"01JBYVFE6T2TVCBMKSSGAH5WR6"}
              colorScheme={colorScheme.light}
              content={launchTextContent.beauty}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"10c7972a"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.beautyLandscape}
            />,
          ],
        })}
      />,
      //Second Row
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgColor
              key={"01JBYVEKZK7QPDBAR71KF3V33K"}
              colorScheme={colorScheme.light}
              content={headingWithBgColorContent.beauty}
            />,
            <TitleWithBgColor
              key={"01JBYCMFB1Z1CJEKHQ89CE96S9"}
              colorScheme={colorScheme.light}
              content={titleWithBgColorContent.beauty}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <MenuPdfs
              key={"01JBYBZHE9XKEY9HS8AGEF3W13"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.beauty}
            />,
            <ProductSingle
              key={"01JBYBZP40QHY6GSY1W718BEAY"}
              colorScheme={colorScheme.dark}
              content={productSingleContent.beauty}
            />,
            <BlogArticleWithImageTop
              key={"01JBYBZSCNTNMJD09KM6GY3E2S"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageTopContent.beauty}
            />,
            <Cart
              key={"01JBYBZX64N4QJXBWYFCZBBQJZ"}
              colorScheme={colorScheme.dark}
              content={cartContent.beauty}
            />,
            <TextLinesWithUpdatesTag
              key={"01JBYC0J872EXRHMKM6WHDBJ4P"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.beauty}
            />,
            <ImageLaunch
              key={"01JBYC0SHNJX4NEQQ5EPAFYC5J"}
              colorScheme={colorScheme.dark}
              content={imagesLaunchContent.beauty}
            />,
            <Unicorns
              key={"01JBYC0Z7F2A4V5HZSV4WA71EG"}
              colorScheme={colorScheme.dark}
              content={unicornsContent.beauty}
            />,
            <BgPhoto
              key={"01JBYC138FV4D4NPNZBY5PASTT"}
              colorScheme={colorScheme.dark}
              content={bgPhotoContent.beautyLandscape}
            />,
            <LaunchText
              key={"01JBYC17BYJ3M15ZWGKA6EC8D6"}
              colorScheme={colorScheme.dark}
              content={launchTextContent.beauty}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"01JBYBRG78KVZN9A4GAXRR07Q3"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoContent.beauty}
            />,
            <EcommItem
              key={"01JBYBRM3ABXWJD0H4QB6T1B3X"}
              colorScheme={colorScheme.bright}
              content={ecommItemContent.beauty}
            />,
            <HeaderHalf
              key={"01JBYBRQXE760NV4SQB8DKJTDM"}
              colorScheme={colorScheme.bright}
              content={headerHalfContent.beauty}
            />,
            <ImageLeft
              key={"01JBYBS09G9YNCS7Z360XM8R5K"}
              colorScheme={colorScheme.bright}
              content={imageLeftContent.beauty}
            />,
            <HeadingWithBgPhotoLeft
              key={"01JBYBS6GY7JH1QXWM1ZGHESQ3"}
              colorScheme={colorScheme.bright}
              content={headingWithBgPhotoLeftContent.beauty}
            />,
            <HeaderBackgroundHalf
              key={"01JBYBSBFY81A8HEG3YNP8SE6G"}
              colorScheme={colorScheme.bright}
              content={headerBackgroundHalfContent.beauty}
            />,
            <HeaderImageButton
              key={"01JBYBSFRJC8P3C6TSJF2W32H6"}
              colorScheme={colorScheme.bright}
              content={headerImageButtonContent.beauty}
            />,
            <BlogArticleWithImageLeft
              key={"01JBYBRBAPRJ2AW2B5YF3ZR199"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.beauty}
            />,
            <ArticleHalf
              key={"01JBYBSMP7S569E3WQSA1ZQEWS"}
              colorScheme={colorScheme.bright}
              content={articleHalfContent.beauty}
            />,
          ],
        })}
      />,

      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"c6296262"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconBeauty}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"662cf277"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.beautySquare}
            />,
          ],
        })}
      />,
    ],
  };

  const industryCards = bentoCards[industry.slug];

  return industryCards;
}
