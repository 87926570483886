import { AspectRatio, Image, Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";
import { BentoCardWidthFiller } from "./bentoCardUtils";

type ProductSingleContentProps = {
  title: string;
  subtitle: string;
  imgs: string[];
};

export const productSingleContent = {
  beauty: {
    title: "Hydration\ncream",
    subtitle: "12 items",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_square",
    }),
  },

  fashion: {
    title: "Designer\nhandbag",
    subtitle: "5 colors",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
  },

  editorial: {
    title: "Digital\nmagazine",
    subtitle: "Latest issue",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
  },

  finance: {
    title: "Investment\napp",
    subtitle: "Pro version",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_square",
    }),
  },

  food: {
    title: "Gourmet\nspices",
    subtitle: "Set of 6",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_square",
    }),
  },

  sport: {
    title: "Fitness\ntracker",
    subtitle: "3 colors",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_square",
    }),
  },
} as const satisfies Record<string, ProductSingleContentProps>;

export default function ProductSingle({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<ProductSingleContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <>
      <Vertical
        w={"100%"}
        fullH
        bg={colorScheme.background}
        center
        gap={"sm"}
        px={"md"}
        pb={"-40px"}
        {...props}
      >
        <AspectRatio ratio={1 / 1} h={100} w={100} mx="auto">
          <Image
            fit="cover"
            src={uploadedImagePath ?? content.imgs[imgIndex]}
            radius={"xxs"}
          />
        </AspectRatio>

        <Title
          ta={"center"}
          variant="card-headline-xs"
          c={colorScheme.textdark}
        >
          {content.title}
        </Title>
        <Text
          ta={"center"}
          variant="card-paragraph-md"
          c={colorScheme.textdark}
        >
          {content.subtitle}
        </Text>
      </Vertical>

      <BentoCardWidthFiller />
    </>
  );
}
