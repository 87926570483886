import { Text } from "@mantine/core";
import { industries, ModalSource, track } from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { InspoSelect } from "./ui/InspoSelect";
import { useUser } from "../contexts/UserContext";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { useIndustry } from "../contexts/IndustryContext";

export const IndustrySection = ({}: {}) => {
  const { user, isPro } = useUser();
  const { industry, setIndustry } = useIndustry();

  return (
    <Horizontal gap={"sm"} noWrap>
      <Text variant="label-lg">INDUSTRY</Text>

      <InspoSelect
        providedData={industries.map((i) => i.label)}
        providedValue={industry.label}
        onClick={(el: string) => {
          if (!el) return;
          if (
            openModalLoginIfNeeded({
              user,
              isMobile: false,
              source: "change_industry",
            })
          ) {
            return;
          }

          const newIndustry = industries.find((i: any) => i.label === el);

          if (newIndustry?.isPro && !isPro) {
            openModalLifetimeIfNeeded({
              user,
              isPro,
              source: ModalSource.INDUSTRY_SECTION,
            });
            return;
          }

          if (!!newIndustry) {
            track({
              event: "change_industry",
              properties: { industry: newIndustry.label },
            });
            setIndustry(newIndustry);
          }
        }}
      />
    </Horizontal>
  );
};
