import {
  BackgroundImage,
  Overlay,
  Space,
  Text,
  Title,
  type CSSProperties,
} from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";
import { OverlayedBackgroundImage } from "../ui/InspoOverlayedBackgroundImage";

type HeadingWithBgPhotoContentProps = {
  labelAboveTitle?: string;
  title: string;
  subtitle?: string;
  labelBelowTitle?: string;
  additionalSpacingBetweenTitleAndSubtitle: boolean;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const headingWithBgPhotoContent = {
  finance: {
    title: "Explore\nNew Horizons",
    subtitle:
      "We invests in technology enabled companies and projects that will decarbonize our planet",
    additionalSpacingBetweenTitleAndSubtitle: true,
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "center",
  },

  editorial: {
    title: "How America Became Obsessed With Protein",
    labelBelowTitle: "by Mary Doe",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "center",
  },

  food: {
    title: "We stand for food freedom",
    subtitle:
      "Enjoy the foods you love and flavors you crave, regardless of dietary lifestyle.",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

  fashion: {
    labelAboveTitle: "About us",
    title: "The Winter\nCollection",
    subtitle:
      "Garments and products so essential that they merge into the wholeness of our lives.",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "center",
  },

  sport: {
    labelAboveTitle: "Sport",
    title: "Further\nand Faster",
    subtitle:
      "Our purpose is to move the world forward through the power of sport.",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "center",
  },

  beauty: {
    // labelAboveTitle: "Sport",
    title: "The intelligence\nof nature",
    // subtitle:
    //   "Our purpose is to move the world forward through the power of sport.",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "center",
  },
} as const satisfies Record<string, HeadingWithBgPhotoContentProps>;

export default function HeadingWithBgPhoto({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<HeadingWithBgPhotoContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  const mainAlignmentStyle: Record<
    HeadingWithBgPhotoContentProps["align"],
    CSSProperties
  > = {
    center: {
      justifyContent: "center",
      alignItems: "center",
      padding: "0 10%",
    },
    bottomLeft: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      paddingRight: "40%",
      paddingBottom: "5%",
    },
  };

  const textAlignmentStyle: Record<
    HeadingWithBgPhotoContentProps["align"],
    CSSProperties
  > = {
    center: {
      textAlign: "center",
    },
    bottomLeft: {
      textAlign: "left",
    },
  };

  return (
    <OverlayedBackgroundImage
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      px="md"
      {...props}
    >
      <Vertical
        gap={0}
        fullW
        fullH
        pos={"relative"}
        px={"md"}
        style={{
          ...mainAlignmentStyle[content.align],
        }}
      >
        {content.labelAboveTitle && (
          <>
            <Text variant="card-label-md" c={colorScheme.textcontrast}>
              {content.labelAboveTitle}
            </Text>
            <Space h={"sm"} />
          </>
        )}

        <Title
          variant="card-display-md"
          c={colorScheme.textcontrast}
          ta={textAlignmentStyle[content.align].textAlign}
        >
          {content.title}
        </Title>

        {content.additionalSpacingBetweenTitleAndSubtitle && <Space h={"xs"} />}

        {content.subtitle && (
          <>
            <Space h={"sm"} />
            <Text
              variant="card-paragraph-lg"
              c={colorScheme.textcontrast}
              ta={textAlignmentStyle[content.align].textAlign}
            >
              {content.subtitle}
            </Text>
          </>
        )}

        {content.labelBelowTitle && (
          <>
            <Space h={"xs"} />
            <Text variant="card-label-md" c={colorScheme.textcontrast}>
              {content.labelBelowTitle}
            </Text>
          </>
        )}
      </Vertical>
    </OverlayedBackgroundImage>
  );
}
