import {
  Button,
  ColorInput,
  ColorSwatch,
  Grid,
  Indicator,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconEdit, IconRotate } from "@tabler/icons-react";
import { useEffect, useRef } from "react";
import {
  type CardColorScheme,
  type PaletteType,
} from "src/utils/colorScheme/palette";
import { ModalSource, track } from "../utils";
import typo from "./../../../styles/Typography.module.scss";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoActionIcon } from "./ui/InspoActionIcon";
import { InspoButton } from "./ui/InspoButton";
import { useUser } from "../contexts/UserContext";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { useColorPalette } from "../contexts/ColorSchemeContext";

export function ColorSchemePickerTabCustom({
  setTab,
}: {
  setTab: (value: "curated" | "custom") => void;
}) {
  const {
    customColorScheme,
    originColorScheme,
    setCustomColorScheme,
    setCustomColorSchemeIsActive,
  } = useColorPalette();

  useEffect(() => {
    if (customColorScheme) return;

    setCustomColorSchemeIsActive(true);
  }, [customColorScheme, originColorScheme]);

  if (!customColorScheme || !originColorScheme)
    return (
      <Vertical center fullH fullW>
        <IconEdit size={20} color="white" />
        <Text variant="paragraph-md" c={"white"}>
          Click Edit on a color scheme to start editing
        </Text>
        <InspoButton
          theme="dark"
          onClick={() => {
            setCustomColorSchemeIsActive(false);
            setTab("curated");
          }}
        >
          Go back
        </InspoButton>
      </Vertical>
    );

  function updateColorScheme(coloSchemeKey, colorKey, colorValue) {
    setCustomColorScheme((prev) => {
      return {
        ...prev,
        [coloSchemeKey]: {
          ...prev[coloSchemeKey],
          [colorKey]: colorValue,
        },
      };
    });
  }

  return (
    <SimpleGrid cols={3} spacing={"xs"} w={"100%"} style={{ zIndex: 3 }}>
      {["dark", "light", "bright"].map((c) => {
        return (
          <ColorSchemeColumn
            key={c}
            ogColorScheme={originColorScheme[c]}
            colorScheme={customColorScheme[c]}
            onChange={(colorKey, colorValue) => {
              updateColorScheme(c, colorKey, colorValue);
            }}
          />
        );
      })}
    </SimpleGrid>
  );
}

function ColorSchemeColumn({
  ogColorScheme,
  colorScheme,
  onChange,
}: {
  ogColorScheme: CardColorScheme;
  colorScheme: CardColorScheme;
  onChange: (colorKey: string, colorValue: string) => void;
}) {
  return (
    <Vertical fullH gap={"xs"}>
      <ColorSchemeCard
        colorScheme={colorScheme}
        ogColorScheme={ogColorScheme}
        onChange={onChange}
      />

      {[
        {
          label: "background",
          key: "background",
        },
        {
          label: "tone",
          key: "tone",
        },
        {
          label: "text",
          key: "textdark",
        },
        {
          label: "text-cta",
          key: "textlight",
        },
        {
          label: "cta",
          key: "ctadark",
        },
        {
          label: "contrast",
          key: "textcontrast",
        },
      ].map(({ label, key }) => {
        return (
          <ColorPickerRow
            key={label}
            label={label}
            ogColor={ogColorScheme[key]}
            color={colorScheme[key]}
            onChange={(color) => onChange(key, color)}
          />
        );
      })}
    </Vertical>
  );
}

function ColorSchemeCard({
  colorScheme,
  ogColorScheme,
  onChange,
}: {
  colorScheme: CardColorScheme;
  ogColorScheme: CardColorScheme;
  onChange: (colorKey: string, colorValue: string) => void;
}) {
  const data = {
    badge: "label",
    title: "Title text",
    subtitle: "This default paragraph will appear on your interface",
    button: "Button",
  };

  const { hovered, ref } = useHover();
  const changedFromOriginal = !Object.keys(ogColorScheme).every(
    (key) =>
      JSON.stringify(ogColorScheme[key]) === JSON.stringify(colorScheme[key]),
  );

  return (
    <Indicator
      color={"gray.5"}
      disabled={!changedFromOriginal}
      size={12}
      position="top-end"
      withBorder
      styles={{
        indicator: {
          borderColor: "#323232",
        },
      }}
    >
      <Vertical
        ref={ref}
        px={"sm"}
        bg={colorScheme.background}
        h={220}
        centerH
        style={{ borderRadius: "var(--mantine-radius-sm)" }}
        pos={"relative"}
      >
        {hovered && changedFromOriginal && (
          <InspoActionIcon
            icon={<IconRotate size={14} />}
            theme="light"
            pos={"absolute"}
            top={4}
            right={4}
            size={24}
            radius={"xl"}
            variant="filled"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              Object.entries(ogColorScheme).forEach(([key, value]) => {
                onChange(key, value);
              });
            }}
          />
        )}

        <Text
          variant="card-label-lg"
          bg={colorScheme.tone}
          c={colorScheme.textdark}
          px={"sm"}
          py={"xs"}
          style={{ zoom: 0.7, borderRadius: "var(--mantine-radius-xs)" }}
        >
          {data.badge}
        </Text>
        <Title
          variant="card-headline-sm"
          c={colorScheme.textdark}
          style={{ zoom: 0.8 }}
        >
          {data.title}
        </Title>
        <Text
          variant="card-paragraph-md"
          c={colorScheme.textdark}
          style={{ zoom: 0.8 }}
          lineClamp={2}
          mt={-16}
        >
          {data.subtitle}
        </Text>
        <Button
          variant="solid"
          w={"100%"}
          h={36}
          radius={"xl"}
          bg={colorScheme.ctadark}
          c={colorScheme.textlight}
        >
          <Text variant="card-paragraph-sm">{data.button}</Text>
        </Button>
      </Vertical>
    </Indicator>
  );
}

function ColorPickerRow({
  label,
  ogColor,
  color,
  onChange,
}: {
  label: string;
  ogColor: string;
  color?: string;
  onChange: (color: string) => void;
}) {
  const { user, isPro } = useUser();
  const colorInputRef = useRef(null);
  const { hovered, ref } = useHover();
  const changedFromOriginal = ogColor.toLowerCase() !== color?.toLowerCase();

  return (
    <Grid columns={12} w={"100%"} px={"xxs"}>
      <Grid.Col span={3.5} m={"auto"}>
        <Text variant="label-sm" c={"white"} style={{ whiteSpace: "nowrap" }}>
          {label}
        </Text>
      </Grid.Col>

      <Grid.Col span={8.5} pos={"relative"} ref={ref}>
        <ColorInput
          ref={colorInputRef}
          withPreview={true}
          withPicker={true}
          fixOnBlur={true}
          onChange={(color) => {
            if (!isPro && document.activeElement !== colorInputRef.current) {
              return;
            }

            if (!color.startsWith("#")) {
              color = `#${color}`;
            }

            onChange(color);
          }}
          onChangeEnd={(color) => {
            if (isPro) return;

            // @ts-ignore
            colorInputRef.current?.blur();
            onChange(ogColor);
            openModalLifetimeIfNeeded({
              user,
              isPro,
              source: ModalSource.COLOR_PICKER,
              additionalTrackProperties: {
                source2: ModalSource.COLOR_PICKER_CUSTOM,
              },
            });
          }}
          leftSectionPointerEvents={changedFromOriginal ? "all" : "none"}
          closeOnColorSwatchClick={false}
          size="sm"
          value={color}
          leftSection={
            <Indicator
              color={"gray.5"}
              position="top-end"
              withBorder
              styles={{
                indicator: {
                  opacity: !changedFromOriginal || hovered ? 0 : 1,
                  borderColor: "#323232",
                },
              }}
            >
              {(!hovered || !changedFromOriginal) && (
                <ColorSwatch
                  size={16}
                  // @ts-ignore
                  color={color}
                  radius={"xxs"}
                  onClick={() => {}}
                />
              )}
              {hovered && changedFromOriginal && (
                <InspoActionIcon
                  icon={<IconRotate size={14} />}
                  theme="light"
                  size={22}
                  radius={"xxs"}
                  variant="filled"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(ogColor);
                  }}
                />
              )}
            </Indicator>
          }
          classNames={{
            input: typo.labelMd,
          }}
          styles={{
            input: {
              color: "white",
              backgroundColor: "transparent",
              borderRadius: "var(--mantine-radius-xs)",
              borderColor: "var(--mantine-color-gray-7)",
            },
          }}
        />
      </Grid.Col>
    </Grid>
  );
}
