import type { BackgroundImageProps } from "@mantine/core";
import { BackgroundImage, Box, Overlay } from "@mantine/core";

export function OverlayedBackgroundImage({
  overlayOpacity = 0.8,
  overlayGradient = "linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 100%)",
  children,
  ...props
}: {
  overlayOpacity?: number;
  overlayGradient?: string;
  children: React.ReactNode;
} & BackgroundImageProps) {
  return (
    <>
      <BackgroundImage {...props}>
        <Overlay
          gradient={overlayGradient}
          opacity={overlayOpacity}
          zIndex={0}
        />
      </BackgroundImage>

      <Box h={"100%"} w={"100%"} pos={"absolute"} top={0} left={0}>
        {children}
      </Box>
    </>
  );
}
