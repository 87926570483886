export function track({ event, properties }) {
  // https://posthog.com/tutorials/event-tracking-guide#setting-up-custom-events

  // @ts-ignore
  window.posthog?.capture(event, properties);
}

export function debounce(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;
  const debouncedFunction = function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };

  debouncedFunction.cancel = function () {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
}

export type IndustryType = (typeof industries)[number];

export enum InspoEvent {
  RANDOM = "random_everything",
  OPEN_LOGIN_MODAL = "open_login_modal",
  SET_HISTORY_CHECKPOINT = "set_history_checkpoint",
}

export const industries = [
  { slug: "foodNDrink", label: "Food & Drink", isPro: false },
  { slug: "ecommerce", label: "Fashion", isPro: false },
  { slug: "beauty", label: "Beauty", isPro: true },
  { slug: "editorial", label: "Editorial", isPro: true },
  { slug: "finance", label: "Finance", isPro: true },
  { slug: "sport", label: "Sport", isPro: true },
];

export const ModalKind = {
  LOGIN: "login",
  LIFETIME: "lifetime",
  SAVED: "saved",
  UPDATES: "updates",
};

export const ModalSource = {
  ADD_TO_FOLDER: "add_to_folder",
  ADD_TO_NEW_FOLDER: "add_to_new_folder",
  SAVE_MOODBOARD_IMG: "save_moodboard_img",
  INDUSTRY_SECTION: "industry",
  COLOR_LOCK: "lock_color_scheme",
  FONT_LOCK_HEADING: "lock_heading",
  FONT_LOCK_BODY: "lock_body",
  CTA: "cta",
  FONT_PICKER: "font_picker",
  FONT_PICKER_LOCAL: "font_picker_local",
  COLOR_PICKER: "color_picker",
  COLOR_PICKER_CUSTOM: "color_picker_custom",
  CHANGE_CARD: "change_card",
  UPLOAD_IMAGE: "upload_image",
};
