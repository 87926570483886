import { Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";

type LaunchTextContentProps = {
  title: string;
  label: string;
  paragraph: string;
  imgs: string[];
};

export const launchTextContent = {
  fashion: {
    title:
      "Whatever your goal is, we're here to help you build habits that lead to progress",
    label: "vision",
    paragraph:
      "Thoughtfully crafted with purpose, each piece tells a story of timeless elegance.",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
  },

  food: {
    title:
      "From farm to table, we're revolutionizing the way people experience food and dining",
    label: "culinary",
    paragraph:
      "Fresh ingredients meet innovative techniques, creating extraordinary flavors for the modern palate.",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
  },
  finance: {
    title:
      "Empowering your financial journey with smart technology and personalized solutions",
    label: "wealth",
    paragraph:
      "Advanced analytics and AI-driven insights help you make informed decisions for your financial future.",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
  },
  editorial: {
    title: "The Last Storytellers: Venice's Vanishing Art of Oral History",
    label: "special report",
    paragraph:
      "A six-month investigation into the disappearing tradition of Mediterranean storytelling.",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
  },
  sport: {
    title:
      "Pushing the boundaries of athletic performance through science and innovation",
    label: "training",
    paragraph:
      "Data-driven training methods and cutting-edge equipment for peak performance.",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
  },
  beauty: {
    title:
      "Redefining beauty through the perfect blend of nature and scientific innovation",
    label: "skincare",
    paragraph:
      "Clean, effective formulations that deliver visible results while nurturing your skin.",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
  },
} as const satisfies Record<string, LaunchTextContentProps>;

export default function LaunchText({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<LaunchTextContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical
      bg={colorScheme.background}
      fullW
      fullH
      spaceBetween
      py={"sm"}
      px={"sm"}
    >
      <Vertical gap={"xs"}>
        <Text variant="card-label-sm" c={colorScheme.textdark}>
          {content.label}
        </Text>
        <Title variant="card-headline-xs" c={colorScheme.textdark} ta={"start"}>
          {content.title}
        </Title>
      </Vertical>
      <Text variant="card-paragraph-md" c={colorScheme.textdark}>
        {content.paragraph}
      </Text>
    </Vertical>
  );
}
