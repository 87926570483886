import { Carousel } from "@mantine/carousel";
import {
  ActionIcon,
  Avatar,
  Box,
  Image,
  SimpleGrid,
  Text,
  Title,
  type ButtonProps,
} from "@mantine/core";
import {
  IconBrandGoogleFilled,
  IconTypography,
  IconClockHour4,
  IconPalette,
  IconX,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { ModalKind, track } from "../utils";
import typo from "./../../../styles/Typography.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoButton } from "./ui/InspoButton";
import { InspoCreatedBy } from "./ui/InspoCreatedBy";
import { InspoSocialProof } from "./ui/InspoSocialProof";
import { modals, type ContextModalProps } from "@mantine/modals";
import { hasOpenNewsletterModalParam, hasShareParams } from "./ShareSection";

export function openModalLoginIfNeeded({
  user,
  isMobile,
  source,
}: {
  user: any;
  isMobile: boolean;
  source: string;
}) {
  if (user) return false;
  if (hasShareParams()) return false;
  if (hasOpenNewsletterModalParam()) return false;

  track({
    event: "open_login_modal",
    properties: { source: source },
  });

  modals.openContextModal({
    modal: ModalKind.LOGIN,
    innerProps: {},
    fullScreen: isMobile,
    onClose: () => modals.close(ModalKind.LOGIN),
    closeOnEscape: true,
    closeOnClickOutside: true,
    withCloseButton: false,
    size: "xl",
    padding: 0,
    centered: true,
  });

  return true;
}

export function ModalLogin({ context }: ContextModalProps<{}>) {
  const isMobile = useIsMobile();

  const data = {
    title: "inspotype",
    header: {
      // title: "We do the curation so you can do the creation",
      // subtitle: "Preview endless combinations of font pairings and color schemes.\n\n Test them on real world industry mockups.",
      title: "Get stunning visual directions. In seconds.",
      subtitle:
        "Access a vast library of fonts, colors, and industry-specific mockups\nto quickly visualize and refine your design concepts.",
    },
    footer: {
      title: "Inspotype is here to stay",
      subtitle:
        "Cut the nonsense from your work, leave the fun.\nJoin us, it's free.",
    },
    loginButton: "Login with Google",
    loginLaunch: {
      title: "Start exploring with Inspotype",
      subtitle:
        "Cut the nonsense from your work, leave the fun.\nJoin us, it's free.",
    },

    longBlackCards: [
      {
        isPro: false,
        textAlign: "left",
        emoji: "😭",
        title: "Is your design process\na fragmented mess?",
        subtitle1:
          "Endless scrolling through font sites, only to end up with mismatched pairings",
        subtitle2:
          "Color palettes that look great in isolation, but clash horribly in your designs.",
        subtitle3:
          "Building mockups from scratch for every project, eating up hours of your day",
        icon1: IconTypography,
        icon2: IconPalette,
        icon3: IconClockHour4,
      },
    ],

    middleTitle: {
      isPro: false,
      emoji: "✨",
      textAlign: "centered",
      title: "There’s an easier way",
      subtitle:
        "Inspotype streamlines your design process, saving you time and boosting creativity.",
      image: undefined,
    },

    sectionTitle: {
      textAlign: "left",
      title1: "Typography Mastery",
      title2: "Your color, your brand",
      subtitle1:
        "Explore curated pairings, mix fonts, and integrate your own typefaces for a unique brand identity.",
      subtitle2:
        "Whatever your design, it will have the perfect palette. Select color schemes and see how they impact your project",
    },

    longCards: [
      // {
      //   isPro: false,
      //   emoji: "😭",
      //   textAlign: "centered",
      //   title: "There’s an easier way",
      //   subtitle:
      //     "Make your creative process more efficient and fast in one seamless experience",
      //   image: undefined,
      // },

      {
        isPro: false,
        textAlign: "left",
        title: "Instant inspiration",
        subtitle:
          "Access industry-specific mockups that kickstart your creativity and impress clients from the get-go.",
        image: "/assets/landing/pic01.png",
      },
      {
        isPro: false,
        textAlign: "right",
        title: "Perfect pairings, effortlessly",
        subtitle:
          "Say goodbye to mismatched fonts with curated pairing to ensure typographic harmony.",
        image: "/assets/landing/pic02.png",
      },
      {
        isPro: false,
        textAlign: "left",
        title: "Cohesive color mastery",
        subtitle:
          "Visualize professionally crafted palettes that work beautifully in real designs, not just in theory.",
        image: "/assets/landing/pic03.png",
      },
    ],

    narrowCards1: [
      {
        isPro: false,
        title: "Font explorer",
        subtitle:
          "Filter through Free, Adobe and commercial type foundries to find the fit for your project.",
        image: "/assets/landing/pic04.png",
      },
      {
        isPro: false,
        title: "Curated pairings",
        subtitle:
          "Access expert-crafted font combinations that work harmoniously together",
        image: "/assets/landing/pic05.png",
      },
      {
        isPro: true,
        title: "Custom Mixer",
        subtitle:
          "Mix and match fonts from our curated list to create unique combinations.",
        image: "/assets/landing/pic06.png",
      },
      {
        isPro: true,
        title: "Local font integration",
        subtitle: "Seamlessly use your own font library within the platform.",
        image: "/assets/landing/pic07.png",
      },
    ],

    narrowCards2: [
      {
        isPro: false,
        title: "Curated color palettes",
        subtitle:
          "Instantly apply professionally crafted color palettes to your chosen mockup.",
        image: "/assets/landing/pic08.png",
      },
      {
        isPro: true,
        title: "Custom color editor",
        subtitle:
          "Take control of your color choices by adjusting and refining curated palettes.",
        image: "/assets/landing/pic09.png",
      },
    ],

    tweetsHeadline: "Designers\nare loving it",
    tweets: [
      {
        displayName: "Nick",
        username: "MrNick_Buzz",
        profileImage: "/assets/landing/MrNick_Buzz.png",
        content:
          "I really enjoyed using Inspotype. It's an asset that every designer/studio will be using in the coming months. \n\nThanks for sharing it and being open to feedback",
      },
      // {
      //   displayName: "Nick Babich",
      //   username: "101babich",
      //   profileImage: "/assets/landing/101babich.png",
      //   content:
      //     "Quickly pair fonts, color schemes & visual aesthetics.\n\n Inspotype is an online tool that pairs fonts with color schemes to create a nice visual aesthetic for digital products.",
      // },
      {
        displayName: "Mike Smith",
        username: "mikesmith187",
        profileImage: "/assets/landing/mikesmith187.png",
        content:
          "This is SO COOL. My clients are always asking for google font alternatives. So it's nice to see some ready made pairings in action.",
      },
      {
        displayName: "Chad Lanega",
        username: "chadlanega",
        profileImage: "/assets/landing/chadlanega.png",
        content:
          "Inspotype is perfect if you need a quick way to test some different typeface and color combinations in a mock interface",
      },
      {
        displayName: "Salman G. Samar",
        username: "sgsamar181",
        profileImage: "/assets/landing/sgsamar181.png",
        content:
          "I think most designers will be using this tool in the future. Definitely worth checking out.",
      },
    ],
  };

  const embedVideoUrl = useMemo(() => {
    const embedOptions = {
      background: 1,
      autoplay: 1,
      loop: 1,
      app_id: 58479,
    };

    const embedOptionsString = Object.entries(embedOptions)
      .map(([key, value]) => `${key}=${value}`)
      .join("&amp;");

    return `https://player.vimeo.com/video/923674626?${embedOptionsString}`;
  }, []);

  return (
    <Vertical noWrap bg={"white"} m={"md"} gap={"xl"}>
      <ActionIcon
        onClick={() => modals.closeAll()}
        pos={"absolute"}
        top={22}
        right={16}
        size={24}
        variant="light"
        tabIndex={-1}
      >
        <IconX size={16} />
      </ActionIcon>
      {/* logo */}
      <Horizontal mx={"auto"} gap={"lg"}>
        <Title variant={"headline-xs"}>{data.title}</Title>
      </Horizontal>

      {/* header */}
      <Vertical maw={"650px"} mx={"auto"} gap={"md"}>
        <Title ta={"center"} variant={isMobile ? "headline-sm" : "display-md"}>
          {data.header.title}
        </Title>

        <Text
          ta={"center"}
          mx={"auto"}
          variant={isMobile ? "paragraph-lg" : "paragraph-xl"}
        >
          {data.header.subtitle}
        </Text>

        <InspoLoginButton trackEventSource="modal_top">
          {data.loginButton}
        </InspoLoginButton>

        <InspoSocialProof />
      </Vertical>

      {/* video */}
      <Vertical
        style={{
          width: "100%",
          margin: "auto",
          overflow: "hidden",
        }}
        pos={"relative"}
      >
        <Image
          src={"/assets/landing/pic00.png"}
          mx={"auto"}
          w={isMobile ? "calc(100%)" : "calc(100% - 20px)"}
          mah={"400px"}
          pos={"absolute"}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        />

        <iframe
          src={embedVideoUrl}
          frameBorder={0}
          style={{
            zIndex: 1,
            height: isMobile ? "200px" : "400px",
            margin: "auto",
            width: "100%",
          }}
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Vertical>

      {/* login
        <InspoLoginButton trackEventSource="modal_social_proof" mt={"-50px"}>
          {data.loginButton}
        </InspoLoginButton> */}

      {/* long black cards */}
      <Vertical fullW gap={"sm"} my={"xl"}>
        {!isMobile &&
          data.longBlackCards.map((card, i) => {
            const hasImage = !!card.icon1;
            return (
              <Box key={i} w={"100%"}>
                <Horizontal
                  bg={"rgba(0, 0, 0, 1)"}
                  my={isMobile ? (hasImage ? 0 : "md") : hasImage ? 0 : "xl"}
                  mx={"auto"}
                  pt={"xl"}
                  pb={"xl"}
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    flexDirection:
                      card.textAlign === "left" ? "row" : "row-reverse",
                    overflow: "hidden",
                  }}
                  noWrap={isMobile ? false : true}
                >
                  <Vertical
                    gap={hasImage ? "lg" : "sm"}
                    mx={"auto"}
                    px={"md"}
                    my={isMobile ? "sm" : 0}
                  >
                    <Text
                      ta={"center"}
                      mx={"auto"}
                      size="lg"
                      style={{ lineHeight: 1 }}
                    >
                      {card.emoji}
                    </Text>
                    <Title
                      ta={"center"}
                      c={"rgba(255, 255, 255, 1)"}
                      mx={"auto"}
                      variant="headline-sm"
                    >
                      {card.title}
                    </Title>
                    <Horizontal center>
                      <Vertical center>
                        <card.icon1
                          strokeWidth={"sm"}
                          size={36}
                          color="rgba(255, 255, 255, 1)"
                        />
                        <Text
                          maw={"200px"}
                          ta={"center"}
                          c={"rgba(255, 255, 255, 1)"}
                          variant="paragraph-lg"
                          pb={"xxxs"}
                        >
                          {card.subtitle1}
                        </Text>
                      </Vertical>

                      <Vertical center>
                        <card.icon2
                          strokeWidth={"sm"}
                          size={36}
                          color="rgba(255, 255, 255, 1)"
                        />
                        <Text
                          maw={"200px"}
                          ta={"center"}
                          c={"rgba(255, 255, 255, 1)"}
                          variant="paragraph-lg"
                          pb={"xxxs"}
                        >
                          {card.subtitle2}
                        </Text>
                      </Vertical>

                      <Vertical center>
                        <card.icon3
                          strokeWidth={"sm"}
                          size={36}
                          color="rgba(255, 255, 255, 1)"
                        />
                        <Text
                          maw={"200px"}
                          ta={"center"}
                          c={"rgba(255, 255, 255, 1)"}
                          variant="paragraph-lg"
                          pb={"xxxs"}
                        >
                          {card.subtitle3}
                        </Text>
                      </Vertical>
                    </Horizontal>
                  </Vertical>
                </Horizontal>
              </Box>
            );
          })}
      </Vertical>

      {/* middle title */}
      <Vertical maw={"400px"} mx={"auto"} my={"lg"}>
        <Title variant="headline-xl" mx={"auto"} ta={"center"}>
          {data.middleTitle.emoji}
        </Title>
        <Title ta={"center"} mx={"auto"} variant="headline-md">
          {data.middleTitle.title}
        </Title>
        <Text variant="paragraph-xl" ta={"center"}>
          {data.middleTitle.subtitle}
        </Text>
      </Vertical>

      {/* long cards */}
      <Vertical fullW gap={"sm"} my={"xl"}>
        {!isMobile &&
          data.longCards.map((card, i) => {
            const hasImage = !!card.image;
            return (
              <Box key={i} w={"100%"}>
                <Horizontal
                  bg={hasImage ? "rgba(245, 245, 245, 1)" : ""}
                  my={isMobile ? (hasImage ? 0 : "md") : hasImage ? 0 : "xl"}
                  mx={"auto"}
                  style={{
                    borderRadius: "var(--mantine-radius-md)",
                    flexDirection:
                      card.textAlign === "left" ? "row" : "row-reverse",
                    overflow: "hidden",
                  }}
                  noWrap={isMobile ? false : true}
                >
                  <Vertical
                    maw={"380px"}
                    gap={hasImage ? "xs" : "sm"}
                    mx={"auto"}
                    px={"md"}
                    my={isMobile ? "sm" : 0}
                  >
                    <Title ta={"left"} variant="headline-sm">
                      {card.title}
                    </Title>
                    <Text ta={"left"} variant="paragraph-lg" pb={"xxxs"}>
                      {card.subtitle}
                    </Text>
                  </Vertical>
                  {hasImage && (
                    <Box w={"70%"}>
                      <Image src={card.image} fit="contain" />
                    </Box>
                  )}
                </Horizontal>
              </Box>
            );
          })}
      </Vertical>

      {/* login launch */}
      <Vertical maw={"400px"} mx={"auto"} my={"xxs"} gap={"xxs"}>
        <Title ta={"center"} mx={"auto"} variant="headline-xs">
          {data.loginLaunch.title}
        </Title>

        {/* <Text variant="paragraph-lg" ta={"center"}>
            {data..subtitle}
          </Text> */}

        <InspoLoginButton trackEventSource="modal_bottom">
          {data.loginButton}
        </InspoLoginButton>
      </Vertical>

      {/* tweets */}
      <Vertical gap={"md"} center fullW my={"xl"} style={{}}>
        <Title ta={"center"} variant="headline-md">
          {data.tweetsHeadline}
        </Title>
        <Carousel
          w={"100%"}
          align={"center"}
          slideSize={isMobile ? "80%" : "60%"}
          slideGap="md"
          orientation="horizontal"
          initialSlide={1}
          loop={true}
          draggable={true}
          dragFree={false}
          withControls={false}
          withIndicators={false}
          skipSnaps={false}
          styles={{
            viewport: {
              height: "100%",
              padding: "0px 20px 50px 0px",
              margin: "-24px",
            },
            container: {
              height: "100%",
            },
          }}
        >
          {data.tweets.map((el, i) => {
            return (
              <Carousel.Slide key={i}>
                <Vertical
                  px="md"
                  py="lg"
                  style={{
                    boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.1)",
                    borderRadius: "var(--mantine-radius-lg)",
                  }}
                >
                  <Horizontal gap={"sm"}>
                    <Avatar size={48} src={el.profileImage} />
                    <Vertical gap={0}>
                      <Text variant="paragraph-md" fw={700}>
                        {el.displayName}
                      </Text>
                      <Text variant="paragraph-md">@{el.username}</Text>
                    </Vertical>
                  </Horizontal>
                  <Text variant="paragraph-lg">{el.content}</Text>
                </Vertical>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </Vertical>

      {/* title section */}
      <Vertical maw={"500px"} my={"lg"}>
        <Title ta={"left"} variant="headline-md">
          {data.sectionTitle.title1}
        </Title>
        <Text variant="paragraph-lg" ta={"left"}>
          {data.sectionTitle.subtitle1}
        </Text>
      </Vertical>

      {/* square cards */}
      <SimpleGrid
        cols={isMobile ? 1 : 2}
        w={"100%"}
        mt={"calc(var(--mantine-spacing-sm) * -2)"}
      >
        {[...(isMobile ? data.longCards : []), ...data.narrowCards1].map(
          (card, i) => {
            const hasImage = !!card.image;
            return (
              <Vertical
                key={i}
                fullW
                spaceBetween
                h={hasImage ? 380 : 200}
                bg={hasImage ? "rgba(245, 245, 245, 1)" : undefined}
                style={{
                  borderRadius: "var(--mantine-radius-md)",
                  overflow: "hidden",
                }}
              >
                <Vertical gap={"xs"} mt={"lg"} px={"md"}>
                  {card.isPro && (
                    <InspoBadge
                      theme="purple"
                      classNames={{ label: typo.labelMd }}
                    >
                      PRO
                    </InspoBadge>
                  )}
                  <Title
                    ta={"left"}
                    variant="headline-xs"
                    mt={!hasImage || card.isPro ? "0" : "md"}
                  >
                    {card.title}
                  </Title>
                  <Text variant="paragraph-lg" ta={"left"}>
                    {card.subtitle}
                  </Text>
                </Vertical>
                {hasImage && (
                  <Vertical>
                    <Image h={400} src={card.image} fit="contain" ml={0} />
                  </Vertical>
                )}
              </Vertical>
            );
          },
        )}
      </SimpleGrid>

      {/* title section */}
      <Vertical maw={"500px"} my={"lg"}>
        <Title ta={"left"} variant="headline-md">
          {data.sectionTitle.title2}
        </Title>
        <Text variant="paragraph-lg" ta={"left"}>
          {data.sectionTitle.subtitle2}
        </Text>
      </Vertical>

      {/* square cards */}
      <SimpleGrid
        cols={isMobile ? 1 : 2}
        w={"100%"}
        mt={"calc(var(--mantine-spacing-sm) * -2)"}
      >
        {[...(isMobile ? data.longCards : []), ...data.narrowCards2].map(
          (card, i) => {
            const hasImage = !!card.image;
            return (
              <Vertical
                key={i}
                fullW
                spaceBetween
                h={hasImage ? 380 : 200}
                bg={hasImage ? "rgba(245, 245, 245, 1)" : undefined}
                style={{
                  borderRadius: "var(--mantine-radius-md)",
                  overflow: "hidden",
                }}
              >
                <Vertical gap={"xs"} mt={"lg"} px={"md"}>
                  {card.isPro && (
                    <InspoBadge
                      theme="purple"
                      classNames={{ label: typo.labelMd }}
                    >
                      PRO
                    </InspoBadge>
                  )}
                  <Title
                    ta={"left"}
                    variant="headline-xs"
                    mt={!hasImage || card.isPro ? "0" : "md"}
                  >
                    {card.title}
                  </Title>
                  <Text variant="paragraph-lg" ta={"left"}>
                    {card.subtitle}
                  </Text>
                </Vertical>
                {hasImage && (
                  <Vertical>
                    <Image h={400} src={card.image} fit="contain" ml={0} />
                  </Vertical>
                )}
              </Vertical>
            );
          },
        )}
      </SimpleGrid>

      {/* footer */}
      <Vertical maw={"400px"} mx={"auto"} my={"xxxl"}>
        <Title ta={"center"} mx={"auto"} variant="headline-xs">
          {data.footer.title}
        </Title>

        <Text variant="paragraph-lg" ta={"center"}>
          {data.footer.subtitle}
        </Text>

        <InspoLoginButton trackEventSource="modal_bottom">
          {data.loginButton}
        </InspoLoginButton>
      </Vertical>

      <InspoCreatedBy />
    </Vertical>
  );
}

function InspoLoginButton({
  trackEventSource,
  children,
  ...props
}: { trackEventSource: string; children: React.ReactNode } & ButtonProps) {
  const isMobile = useIsMobile();

  return (
    <form
      action={`/api/auth/signin?signinOrigin=${isMobile ? "mobile" : "desktop"}`}
      method="POST"
      style={{ margin: "auto" }}
    >
      <InspoButton
        // @ts-ignore
        type="submit"
        theme="dark"
        icon={<IconBrandGoogleFilled />}
        iconPos="left"
        mx={"auto"}
        mt={"md"}
        classNames={{
          label: typo.paragraphMd,
        }}
        onClick={() => {
          track({
            event: "login",
            properties: { source: trackEventSource },
          });
        }}
        {...props}
      >
        {children}
      </InspoButton>
    </form>
  );
}