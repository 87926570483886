import { Anchor, Highlight, Input, ScrollArea } from "@mantine/core";
import { useClipboard, useSessionStorage } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconLock, IconSearch } from "@tabler/icons-react";
import { useEffect, useMemo, useRef } from "react";
import { ModalSource, track } from "../utils";
import typo from "./../../../styles/Typography.module.scss";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoPickerListButton } from "./ui/InspoPickerListButton";
import { useUser } from "../contexts/UserContext";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { useFont, useFonts } from "../contexts/FontsContext";

export function FontPickerTabLocal({
  type,
  setShow,
}: {
  type: "heading" | "body";
  setShow: (value: boolean) => void;
}) {
  const { user, isPro } = useUser();
  const { localFonts, fetchLocalFonts } = useFonts();
  const { font, isLockedAt, setIsLockedAt, setIsLockedAtFont, setIsLocal } =
    useFont(type);

  const clipboard = useClipboard();

  const [localFontSearchValue, setLocalFontSearchValue] = useSessionStorage({
    key: {
      heading: `local_font_search_value_heading`,
      body: `local_font_search_value_body`,
    }[type],
    defaultValue: "",
  });

  const filteredLocalFonts = useMemo(() => {
    return localFonts
      .filter((f: any) =>
        f.family.toLowerCase().includes(localFontSearchValue.trim()),
      )
      .sort((a: any, b: any) => a.family.localeCompare(b.family));
  }, [localFontSearchValue, localFonts]);

  const viewportRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const selectedIndex = filteredLocalFonts.findIndex(
      (f: any) => f.family === font["readable-font-family"],
    );

    viewportRef.current
      ?.querySelectorAll("[data-list-item]")
      ?.[selectedIndex]?.scrollIntoView({ block: "center" });
  }, []);

  return (
    <Vertical fullW fullH gap={0} style={{ zIndex: 3 }}>
      <Input
        placeholder="Search a font"
        w={"100%"}
        leftSection={<IconSearch size={16} color="white" />}
        value={localFontSearchValue}
        autoFocus
        onChange={(e) => setLocalFontSearchValue(e.currentTarget.value)}
        classNames={{
          input: typo.paragraphMd,
        }}
        styles={{
          input: {
            color: "white",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderColor: "transparent",
          },
        }}
      />
      {localFonts.length === 0 && (
        <Anchor
          mt={"lg"}
          underline={"always"}
          ta={"center"}
          c={"white"}
          mx={"auto"}
          classNames={{ root: typo.paragraphMd }}
          component="button"
          onClick={async (e) => {
            async function checkLocalFontsPermission() {
              if ("queryLocalFonts" in window) {
                const status = await navigator.permissions.query({
                  // @ts-ignore
                  name: "local-fonts",
                });
                if (status.state === "prompt") {
                  // local font access is available
                } else if (status.state === "granted") {
                  console.log("Local font access is granted");
                  fetchLocalFonts();
                } else if (status.state === "denied") {
                  clipboard.copy(
                    "chrome://settings/content/siteDetails?site=" +
                      window.location.origin,
                  );
                  notifications.show({
                    id: "local-font-access",
                    title: "Local font access denied",
                    message: (
                      <>
                        Visit the link we copied to your clipboard to enable
                        local font access. <br /> Source:{" "}
                        <Anchor
                          href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DDesktop"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontFamily: "Satoshi",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "21.6px",
                            color: "black",
                          }}
                        >
                          Google Chrome Help
                        </Anchor>
                      </>
                    ),
                    color: "red",
                    icon: <IconLock size={14} />,
                  });
                }
              } else {
                console.log("Local font access is not available");
              }
            }
            async function requestLocalFontsPermission(event: any) {
              event.preventDefault();
              try {
                // @ts-ignore
                await window.queryLocalFonts();
                const status = await navigator.permissions.query({
                  // @ts-ignore
                  name: "local-fonts",
                });
                if (status) {
                  checkLocalFontsPermission();
                }
              } catch (e) {
                // Handle error, e.g. user cancelled the operation.
              }
            }

            track({
              event: "local_fonts_permission_request",
              properties: {},
            });
            checkLocalFontsPermission();
            requestLocalFontsPermission(e);
          }}
        >
          Inspotype needs access to local fonts
        </Anchor>
      )}
      <ScrollArea
        viewportRef={viewportRef}
        mt={"xxs"}
        style={{
          height: "385px",
          width: "100%",
        }}
      >
        {filteredLocalFonts.map((item: any, index: number) => {
          return (
            <InspoPickerListButton
              key={item.fullName}
              selected={item.family === font["readable-font-family"]}
              onClick={() => {
                if (!isPro) {
                  openModalLifetimeIfNeeded({
                    user,
                    isPro,
                    source: ModalSource.FONT_PICKER,
                    additionalTrackProperties: {
                      source2: ModalSource.FONT_PICKER_LOCAL,
                    },
                  });
                  return;
                }

                if (isLockedAt === undefined) {
                  setIsLockedAt(index);
                }
                setIsLockedAtFont(item.family);
                setIsLocal(true);
                setShow(false);

                track({
                  event: "select_font",
                  properties: { category: "local" },
                });
              }}
            >
              <Highlight
                highlight={localFontSearchValue}
                c={
                  item.family === font["readable-font-family"]
                    ? "black"
                    : undefined
                }
                ta={"left"}
                classNames={{ root: typo.paragraphMd }}
                style={{
                  fontFamily: item.family,
                  fontWeight: item.weight,
                }}
                highlightStyles={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {item.family}
              </Highlight>
            </InspoPickerListButton>
          );
        })}
      </ScrollArea>
    </Vertical>
  );
}
